import axios from '../axios'

/*
 * 照护计划扩展管理模块
 */

// 保存
export const save = (data) => {
    return axios({
        url: '/customerservicecontentext/save',
        method: 'post',
        data
    })
}

// 带着计划时间的保存
export const savewithplantime = (data) => {
  return axios({
    url: '/customerservicecontentext/savewithplantime',
    method: 'post',
    data
  })
}

// 删除
export const batchDelete = (data) => {
    return axios({
        url: '/customerservicecontentext/delete',
        method: 'post',
        data
    })
}
// 根据baseid查询扩展配置
export const findByBaseId = (params) => {
    return axios({
        url: '/customerservicecontentext/findByBaseId',
        method: 'get',
        params
    })
}

// 根据baseid查询扩展配置,只查询按需
export const findByBaseIdNess = (params) => {
  return axios({
    url: '/customerservicecontentext/findByBaseIdNess',
    method: 'get',
    params
  })
}

// 根据baseid删除扩展配置
export const deleteByBaseId = (params) => {
  return axios({
    url: '/customerservicecontentext/deleteByBaseId',
    method: 'get',
    params
  })
}
