import axios from '../axios'

/*
 * 客户管理模块
 */

// 保存
export const save = (data) => {
    return axios({
        url: '/customer/save',
        method: 'post',
        data
    })
}

// 删除单条
export const singleDelete = (data) => {
  return axios({
    url: '/customer/singleDelete',
    method: 'post',
    data
  })
}

// 删除
export const batchDelete = (data) => {
    return axios({
        url: '/customer/delete',
        method: 'post',
        data
    })
}
// 分页查询
export const findPage = (data) => {
    return axios({
        url: '/customer/findPage',
        method: 'post',
        data
    })
}

// 查找全部
export const findAll = () => {
  return axios({
    url: '/customer/findAll',
    method: 'post'
  })
}

// 导出Excel长者信息
export const exportCrmCustomerExcelFile = (data) => {
    return axios({
        url: '/customer/exportCrmCustomerExcelFile',
        method: 'post',
        data
    })
}
// 查找长者的菜单权限标识集合
export const findPermissions = (params) => {
    return axios({
        url: '/customer/findPermissions',
        method: 'get',
        params
    })
}
// 根据用户名查找
export const findByName = (params) => {
    return axios({
        url: '/customer/findByName',
        method: 'get',
        params
    })
}
// 根据用户id查找
export const findById = (params) => {
  return axios({
    url: '/customer/findById',
    method: 'get',
    params
  })
}
// 根据用户名查找修改记录，分页查询
export const findChangeLogPage = (data) => {
  return axios({
    url: '/customer/findChangeLogPage',
    method: 'post',
    data
  })
}

// 获取客户性别分布统计。
export const findGenderStatics = () => {
  return axios({
    url: '/customer/findGenderStatics',
    method: 'get'
  })
}

// 获取客户来源分布统计。
export const findSourceStatics = () => {
  return axios({
    url: '/customer/findSourceStatics',
    method: 'get'
  })
}

// 获取客户入住时长分布统计。
export const findDurationStatics = () => {
  return axios({
    url: '/customer/findDurationStatics',
    method: 'get'
  })
}

// 获取客户护理级别分布统计。
export const findCareLevelStatics = () => {
  return axios({
    url: '/customer/findCareLevelStatics',
    method: 'get'
  })
}

// 获取客户护理级别性别分布统计。
export const findCareLevelOfGenderStatics = () => {
  return axios({
    url: '/customer/findCareLevelOfGenderStatics',
    method: 'get'
  })
}

// 获取客户年龄分布统计。
export const findAgeGroupOfGenderStatics = () => {
  return axios({
    url: '/customer/findAgeGroupOfGenderStatics',
    method: 'get'
  })
}
