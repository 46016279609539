import axios from '../axios'

/*
 * 机构管理模块
 */

// 保存
export const save = (data) => {
    return axios({
        url: '/org/save',
        method: 'post',
        data
    })
}
// 删除
export const batchDelete = (data) => {
    return axios({
        url: '/org/delete',
        method: 'post',
        data
    })
}
// 分页查询
export const findPage = (data) => {
    return axios({
        url: '/org/findPage',
        method: 'post',
        data
    })
}
// 查询全部机构
export const findAll = (data) => {
  return axios({
    url: '/org/findAll',
    method: 'post',
    data
  })
}
// 根据机构名查找
export const findByName = (params) => {
    return axios({
        url: '/org/findByName',
        method: 'get',
        params
    })
}
