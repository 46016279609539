/*
 * 测试数据模块
 */

//  测试数据
export const testData = {
  "code": 200,
  "msg": null,
  "data": {
    "authorities": [],
    "details": {
      "remoteAddress": "0:0:0:0:0:0:0:1",
      "sessionId": "E9E774A8EB4405B25692D84B4521CB45"
    }
  }
}

export default {
  testData
}
