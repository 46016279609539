<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import {version} from 'element-ui/package.json'
import Cookies from "js-cookie";

export default {
  name: 'App',
  watch: {
    theme(val, oldVal)
    {
      if (typeof val !== 'string') return
      console.log(val);
    }
  },
  mounted()
  {

  }
}
</script>
<style lang="scss">
@import "./assets/public/scss/themefy.scss";
</style>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*margin-top: 60px;*/
}
.el-popover{
  height: 400px;
  overflow: auto;
}
</style>
