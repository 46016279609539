import axios from '../axios'

/*
 * 班次管理模块
 */

// 保存
export const save = (data) => {
    return axios({
        url: '/careschedual/save',
        method: 'post',
        data
    })
}

// 删除
export const batchDelete = (data) => {
    return axios({
        url: '/careschedual/delete',
        method: 'post',
        data
    })
}

// 分页查询
export const findPage = (data) => {
  return axios({
    url: '/careschedual/findPage',
    method: 'post',
    data
  })
}

// 获取所有的班次
export const findAllCareScheduals = () => {
  return axios({
    url: '/careschedual/findAllCareScheduals',
    method: 'get'
  })
}
