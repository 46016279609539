import axios from '../axios'

/*
 * 日历扩展管理模块
 */

// 保存
export const save = (data) => {
    return axios({
        url: '/calendarext/save',
        method: 'post',
        data
    })
}
// 删除
export const batchDelete = (data) => {
    return axios({
        url: '/calendarext/delete',
        method: 'post',
        data
    })
}
// 根据baseid查询扩展配置
export const findByBaseId = (params) => {
    return axios({
        url: '/calendarext/findByBaseId',
        method: 'get',
        params
    })
}
