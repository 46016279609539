import axios from '../axios'

/*
 * 客户合同模块
 */

// 保存
export const save = (data) => {
    return axios({
        url: '/customercontract/save',
        method: 'post',
        data
    })
}
// 删除
export const batchDelete = (data) => {
    return axios({
        url: '/customercontract/delete',
        method: 'post',
        data
    })
}
// 根据customerid查询详细信息
export const findByCustomerId = (params) => {
    return axios({
        url: '/customercontract/findByCustomerId',
        method: 'get',
        params
    })
}

// 分页查询
export const findPage = (data) => {
  return axios({
    url: '/customercontract/findPage',
    method: 'post',
    data
  })
}

