var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-color-picker", {
    staticClass: "theme-picker",
    attrs: { "popper-class": "theme-picker-dropdown", size: _vm.size },
    model: {
      value: _vm.theme,
      callback: function($$v) {
        _vm.theme = $$v
      },
      expression: "theme"
    }
  })
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0617380d", { render: render, staticRenderFns: staticRenderFns })
  }
}