<template>
  <!-- TODO: 2023.06.26. hard code. stone. 目录：隐藏'通用配置'的显示. 要上下同时控制才可以，有点奇怪.-->
  <el-submenu v-if="menu.children && menu.children.length >= 1 && menu.name !== '通用配置'" :index="'' + menu.id">
    <template slot="title">
      <i :class="menu.icon" ></i>
      <span slot="title">{{menu.name}}</span>
    </template>
    <MenuTree v-for="item in menu.children" :key="item.id" :menu="item"></MenuTree>
  </el-submenu>
  <!-- TODO: 2022.12.28. hard code. stone. 菜单：隐藏'客户详情' 菜单和'照护计划服务内容配置' 和 '考勤统计明细'和'套餐详情' 和 '医嘱药单' 和 '账单明细' 和'照护计划详情' 和 '照护计划详情按需'的显示. -->
  <el-menu-item v-else-if = "menu.name !== '客户详情'&& menu.name !== '医嘱药单'
  && menu.name !== '账单明细' && menu.name !== '套餐详情' && menu.name !== '照护计划服务内容配置' && menu.name !== '照护计划详情'  && menu.name !== '照护计划详情按需'
  && menu.name !== '考勤统计明细'  && menu.name !== '通用配置' && menu.name !== '工单统计详情' && menu.name !== '模版详情(菜单不显示)' && menu.name !== '评估详情(菜单不显示)' " :index="'' + menu.id"  @click="handleRoute(menu)">
    <i :class="menu.icon"></i>
    <span slot="title">{{menu.name}}</span>
  </el-menu-item>
</template>

<script>
import { getIFrameUrl, getIFramePath } from '@/utils/iframe'
export default {
  name: 'MenuTree',
  props: {
    menu: {
      type: Object,
      required: true
    }
  },
  methods: {
    handleRoute (menu) {
      // 如果是嵌套页面，转换成iframe的path
      let path = getIFramePath(menu.url)
      if(!path) {
        path = menu.url
      }
      // 通过菜单URL跳转至指定路由
      this.$router.push("/" + path)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
