import axios from '../axios'

/*
 * 服务项目基础模块
 */

// 保存
export const save = (data) => {
    return axios({
        url: '/servicecontentinfo/save',
        method: 'post',
        data
    })
}

// 删除单条
export const singleDelete = (data) => {
  return axios({
    url: '/servicecontentinfo/singleDelete',
    method: 'post',
    data
  })
}

// 批量删除
export const batchDelete = (data) => {
    return axios({
        url: '/servicecontentinfo/delete',
        method: 'post',
        data
    })
}

// 分页查询
export const findPage = (data) => {
  return axios({
    url: '/servicecontentinfo/findPage',
    method: 'post',
    data
  })
}

// 分页查询,加上子节点.
export const findTreePage = (data) => {
  return axios({
    url: '/servicecontentinfo/findTreePage',
    method: 'post',
    data
  })
}

// 查找所有
export const findAll = () => {
  return axios({
    url: '/servicecontentinfo/findAll',
    method: 'post'
  })
}

// 查找所有父级服务内容
export const findParentAll = () => {
  return axios({
    url: '/servicecontentinfo/findParentAll',
    method: 'post'
  })
}

// 根据id查询服务内容
export const findById = (params) => {
  return axios({
    url: '/servicecontentinfo/findById',
    method: 'get',
    params
  })
}

// 根据ServiceItemDictId查询服务内容
export const findByServiceItemDictId = (params) => {
    return axios({
        url: '/servicecontentinfo/findByServiceItemDictId',
        method: 'get',
        params
    })
}

// 查询服务内容树
export const findServiceContentTree = (params) => {
  return axios({
    url: '/servicecontentinfo/findTree',
    method: 'get',
    params
  })
}

// 根据服务内容ID查询服务内容子级，即服务要求.
export const findServiceContentChild = (params) => {
  return axios({
    url: '/servicecontentinfo/findServiceContentChild',
    method: 'get',
    params
  })
}
