import axios from '../axios'

/*
 * 套餐服务项目基础模块
 */

// 保存
export const save = (data) => {
    return axios({
        url: '/combinationservicecontentinfo/save',
        method: 'post',
        data
    })
}
// 删除
export const batchDelete = (data) => {
    return axios({
        url: '/combinationservicecontentinfo/delete',
        method: 'post',
        data
    })
}

// 分页查询
export const findPage = (data) => {
  return axios({
    url: '/combinationservicecontentinfo/findPage',
    method: 'post',
    data
  })
}

// 分页查询汇总
export const findPageCombinationSummary = (data) => {
  return axios({
    url: '/combinationservicecontentinfo/findPageCombinationSummary',
    method: 'post',
    data
  })
}

// 根据CombinationLevelId查询扩展配置
export const findServiceContentExtByCombinationLevelId = (params) => {
  return axios({
    url: '/combinationservicecontentinfo/findServiceContentExtByCombinationLevelId',
    method: 'get',
    params
  })
}
