import axios from '../axios'

/*
 * 家具管理模块
 */

// 保存
export const save = (data) => {
    return axios({
        url: '/equipment/save',
        method: 'post',
        data
    })
}
// 删除
export const batchDelete = (data) => {
    return axios({
        url: '/equipment/delete',
        method: 'post',
        data
    })
}
// 查询家具
export const findBySpaceId = (params) => {
    return axios({
        url: '/equipment/findBySpaceId',
        method: 'get',
        params
    })
}
