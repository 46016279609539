import axios from '../axios'

/*
 * 标签管理模块
 */

// 获取所有标签组
export const findAllTagGroup = () => {
  return axios({
    url: '/tag/findAllTagGroup',
    method: 'get'
  })
}

// 获取所有标签组
export const findTagByGroup = (params) => {
  return axios({
    url: '/tag/findTagByGroup',
    method: 'get',
    params
  })
}

// 获取标签
export const getTags = (data) => {
    return axios({
        url: '/tag/findAll',
        method: 'post',
        data
    })
}

// 保存标签组
export const saveGroup = (data) => {
    return axios({
        url: '/tag/saveGroup',
        method: 'post',
        data
    })
}

// 删除标签组
export const deleteGroup = (data) => {
  return axios({
    url: '/tag/deleteGroup',
    method: 'post',
    data
  })
}


// 保存标签
export const saveTags = (data) => {
    return axios({
        url: '/tag/saveTags',
        method: 'post',
        data
    })
}

// 获取客户标签
export const findCustomerTags = (params) => {
  return axios({
    url: '/tag/findCustomerTags',
    method: 'get',
    params
  })
}

// 保存客户标签
export const saveCustomerTags = (data) => {
  return axios({
    url: '/tag/saveCustomerTags',
    method: 'post',
    data
  })
}
