import axios from '../axios'

/*
 * 照护计划扩展计划日期时间管理模块
 */

// 保存
export const save = (data) => {
    return axios({
        url: '/customerservicecontentextplan/save',
        method: 'post',
        data
    })
}
// 删除
export const batchDelete = (data) => {
    return axios({
        url: '/customerservicecontentextplan/delete',
        method: 'post',
        data
    })
}
// 根据baseid查询扩展配置
export const findByBaseId = (params) => {
    return axios({
        url: '/customerservicecontentextplan/findByBaseId',
        method: 'get',
        params
    })
}
