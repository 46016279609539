import axios from '../axios'

/*
 * 客户健康管理模块
 */

// 保存
export const save = (data) => {
    return axios({
        url: '/customerhealthinfo/save',
        method: 'post',
        data
    })
}

// 删除
export const batchDelete = (data) => {
    return axios({
        url: '/customerhealthinfo/delete',
        method: 'post',
        data
    })
}

// 根据customerid删除信息
export const deleteByCustomerId = (params) => {
  return axios({
    url: '/customerhealthinfo/deleteByCustomerId',
    method: 'get',
    params
  })
}

// 根据customerid查询详细信息
export const findByCustomerId = (params) => {
    return axios({
        url: '/customerhealthinfo/findByCustomerId',
        method: 'get',
        params
    })
}
