import axios from '../axios'

/*
 * 金融类型管理模块
 */

// 保存
export const save = (data) => {
    return axios({
        url: '/customerfinancetype/save',
        method: 'post',
        data
    })
}
// 删除
export const batchDelete = (data) => {
    return axios({
        url: '/customerfinancetype/delete',
        method: 'post',
        data
    })
}
// 查询金融类型树
export const findFinanceTypeTree = (params) => {
    return axios({
        url: '/customerfinancetype/findTree',
        method: 'get',
        params
    })
}

// 查询全部
export const findAll = () => {
  return axios({
    url: '/customerfinancetype/findAll',
    method: 'get'
  })
}

// 查询全部金融类型
export const findAllFinanceType = () => {
  return axios({
    url: '/customerfinancetype/findAllFinanceType',
    method: 'get'
  })
}

