var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("nav-bar"),
      _vm._v(" "),
      _c("head-bar", { on: { headbartransfer: _vm.refreshCurrentTab } }),
      _vm._v(" "),
      _c("main-content", { ref: "childmaincontent" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5e265133", { render: render, staticRenderFns: staticRenderFns })
  }
}