import axios from '../axios'

/*
 * 地址区域管理模块
 */

// 保存
export const save = (data) => {
    return axios({
        url: '/area/save',
        method: 'post',
        data
    })
}
// 删除
export const batchDelete = (data) => {
    return axios({
        url: '/area/delete',
        method: 'post',
        data
    })
}
// 查询地址区域树
export const findAreaTree = () => {
    return axios({
        url: '/area/findTree',
        method: 'get'
    })
}

// 查询地址区域树分页
export const findTreePage = (data) => {
  return axios({
    url: '/area/findTreePage',
    method: 'post',
    data
  })
}

// 查询全部
export const findAll = () => {
  return axios({
    url: '/area/findAll',
    method: 'get'
  })
}
