<template>
  <el-select v-model="cOrg.id" placeholder="机构" class="org" @change="onChangeOrg" >
    <el-option
      v-for="item in orgs"
      :key="item.id"
      :label="item.name"
      :value="item.id">
    </el-option>
  </el-select>
</template>

<script>
import Cookies from "js-cookie"

export default {
  name: 'OrgPanel',
  props: {
    orgs: {
      type: Array
    }
  },
  data()
  {
    return {
      cOrg: null
    }
  },
  methods: {
    onChangeOrg(orgId)
    {
      //切换默认机构并刷新token.
      var user = sessionStorage.getItem("user")

      //保存当前机构,刷新token.
      if (user) {

        let _orgs = this.orgs.filter(o => o.id === orgId);
        let _org = _orgs.length > 0 ? _orgs[0] : {};

        let params = {orgId: orgId, orgName: _org.name}
        this.$api.user.refreshtokenOrgAndFindUserCurrentOrgs(params).then((res) => {
          if (res.code === 200) {
            let token = res.data.token
            Cookies.set('token', token) // 放置token到Cookie
            this.cOrg = res.data.currentOrg

            // 将currentOrg存入session中.
            sessionStorage.setItem('currentOrgId', res.data.currentOrg.id) // 保存机构到本地会话

            //触发父组件的transfer方法， 将this.cOrg传到父组件.
            this.$emit('transfer', this.cOrg)
          }
        })
      }
    }

  },
  mounted()
  {
    var user = sessionStorage.getItem("user")

    //载入用户可访问的当前的机构信息，来自后台接口.
    if (user)
    {
      this.$api.user.findUserCurrentOrgs().then((res) =>
      {
        if (res.code === 200)
        {
          this.cOrg = res.data
          //触发父组件的transfer方法， 将this.cOrg传到父组件.
          // TODO: 2024.01.05. stone zhao. 第一个登录页面，不需要进行刷新页面操作.
          // this.$emit('transfer', this.cOrg)
        }
      })
    }
  },
  watch: {
    orgs(val)
    {
      // console.log(val, '子组件-------------------');
      // console.log(val[0]);
      // console.log(val[1]);
    }
  }
}
</script>

<style scoped>
</style>
