<template>
  <div class="container">
    <!-- 导航菜单栏 -->
    <nav-bar></nav-bar>
    <!-- 头部区域 -->
    <head-bar @headbartransfer="refreshCurrentTab"></head-bar>
    <!-- 主内容区域 -->
    <main-content ref="childmaincontent"></main-content>
  </div>
</template>

<script>
import HeadBar from "./HeadBar"
import NavBar from "./NavBar"
import MainContent from "./MainContent"
import {mapState} from "vuex";

export default {
  components: {
    HeadBar,
    NavBar,
    MainContent
  },
  methods: {
    refreshCurrentTab()
    {
      //调用子组件方法，刷新当前标签页面.
      //TODO: 2023.04.15. stone hard code. 对于"待办"要进行特殊的处理。
      //TODO: 2023.04.15. stone 切换机构后，切换的页面会成为第一个tab，导致重新排序，这个后续再处理。
      //TODO: 2024.01.05. stone zhao 因为使用keep-alive保存了页面缓存，所以此处进行全页面的全部刷新。
      this.$refs.childmaincontent.tabsRefreshCurrentHandle()
      /*
      if (this.mainTabsActiveName === '待办')
      {
        this.$refs.childmaincontent.tabsRefreshCurrentHandleFirst()
      }
      else {
        this.$refs.childmaincontent.tabsRefreshCurrentHandle()
      }

       */
    }
  },
  computed: {
    mainTabs: {
      get()
      {
        return this.$store.state.tab.mainTabs
      },
      set(val)
      {
        this.$store.commit('updateMainTabs', val)
      }
    },
    mainTabsActiveName: {
      get()
      {
        return this.$store.state.tab.mainTabsActiveName
      },
      set(val)
      {
        this.$store.commit('updateMainTabsActiveName', val)
      }
    }
  }
};
</script>

<style scoped lang="scss">
.container {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  // background: rgba(224, 234, 235, 0.1);
  background: linear-gradient(to bottom, #E6E8EB, #F5F7FA);
}
</style>
