import axios from '../axios'

/*
 * 房间管理模块
 */

// 保存
export const save = (data) => {
    return axios({
        url: '/space/save',
        method: 'post',
        data
    })
}
// 删除
export const batchDelete = (data) => {
    return axios({
        url: '/space/delete',
        method: 'post',
        data
    })
}
// 查询房间树
export const findSpaceTree = (params) => {
    return axios({
        url: '/space/findTree',
        method: 'get',
        params
    })
}

// 根据客户编号查询房间
export const findSpaceByCustomerId = (params) => {
  return axios({
    url: '/space/findSpaceByCustomerId',
    method: 'get',
    params
  })
}


// 查询机构下所有的有住户住的床位,要显示出完整的楼、层、和房间信息.
export const findRooms = () => {
  return axios({
    url: '/space/findRooms',
    method: 'get'
  })
}

// 查询机构下所有的床位,只有床位信息;
export const findAllRooms = () => {
  return axios({
    url: '/space/findAllRooms',
    method: 'get'
  })
}

// 查询机构下所有的床位,房间+床位信息.
export const findAllCombineRooms = () => {
  return axios({
    url: '/space/findAllCombineRooms',
    method: 'get'
  })
}

// 查询父节点下所有的床位
export const findPageRoomsByParentId = (data) => {
  return axios({
    url: '/space/findPageRoomsByParentId',
    method: 'post',
    data
  })
}

// 查询父节点下所有的床位信息：以tree的格式,需要包含床位的住户等完整信息：
// 房间 -> 床位
export const findTreeRoomsByParentId = (params) => {
  return axios({
    url: '/space/findTreeRoomsByParentId',
    method: 'get',
    params
  })
}

// 查询所有楼栋
export const findBuildings = () => {
  return axios({
    url: '/space/findBuildings',
    method: 'get'
  })
}


// 查询当前楼栋的所有楼层树状结构.
export const findBuildingTreeData = (params) => {
  return axios({
    url: '/space/findBuildingTreeData',
    method: 'get',
    params
  })
}

// 查询当前楼栋的所有楼层.
export const findFloors = (params) => {
  return axios({
    url: '/space/findFloors',
    method: 'get',
    params
  })
}

// 根据客户信息更新房态
export const updateCustomerRoom = (params) => {
  return axios({
    url: '/space/updateCustomerRoom',
    method: 'get',
    params
  })
}

// 查询入住统计信息.有父节点编号就是当前父节点下的信息，没有编号就是机构下全部信息.
export const findRoomsStatics = (params) => {
  return axios({
    url: '/space/findRoomsStatics',
    method: 'get',
    params
  })
}
