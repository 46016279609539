import axios from '../axios'

/*
 * 部门管理模块
 */

// 保存
export const save = (data) => {
    return axios({
        url: '/dept/save',
        method: 'post',
        data
    })
}
// 删除
export const batchDelete = (data) => {
    return axios({
        url: '/dept/delete',
        method: 'post',
        data
    })
}
// 查询部门树
export const findDeptTree = (params) => {
    return axios({
        url: '/dept/findTree',
        method: 'get',
        params
    })
}

// 查询全部
export const findAll = () => {
  return axios({
    url: '/dept/findAll',
    method: 'get'
  })
}

// 查询全部部门
export const findAllDept = () => {
  return axios({
    url: '/dept/findAllDept',
    method: 'get'
  })
}

