<template>
  <div class="theme-selector">
    <el-popover placement="bottom" title="主题" width="200" trigger="hover">
      <el-button slot="reference" type="text" @click="dialogVisible = true">
        <i class="el-icon-school" style="color: #00b6ed;"></i>
      </el-button>
      <div>
        <el-table :data="themeList">
          <el-table-column prop="title" align="center" />
          <el-table-column prop="address" align="center">
            <template slot-scope="scope">
              <el-button size="mini" v-if="theme === scope.row.name" type="success" icon="el-icon-check" round>已激活
              </el-button>
              <el-button size="mini" v-else round @click="handleSelectTheme(scope.row.name)">使用</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-popover>

  </div>
  <!--  <el-color-picker class="theme-selector" popper-class="theme-picker-dropdown"-->
  <!--    v-model="theme" :size="size">-->
  <!--  </el-color-picker>-->
</template>

<script>

// const version = require('element-ui/package.json').version // element-ui
const ORIGINAL_THEME = 'dark' // default color

export default {
  name: 'ThemeSelector',
  props: {
    default: { // 初始化主题，可由外部传入
      type: String,
      default: null
    },
    size: { // 初始化主题，可由外部传入
      type: String,
      default: 'small'
    }
  },
  data() {
    return {
      // chalk: '', // content of theme-chalk css
      theme: ORIGINAL_THEME,
      dialogVisible: false,
      // showSuccess: true, // 是否弹出换肤成功消息
      themeList: [
        { 'name': 'light', 'title': '浅色', 'checked': true },
        { 'name': 'dark', 'title': '深色', 'checked': false }
      ]
    }
  },
  mounted() {
    if (this.default != null) {
      this.theme = this.default
      this.$emit('onThemeChange', this.theme)
      this.showSuccess = false
    }
    else {
      this.$emit('onThemeChange', this.theme)
    }
  },
  watch: {
    theme(val, oldVal) {
      if (typeof val !== 'string') return
      if (val === oldVal) return

      this.$emit('onThemeChange', val)

      // if(this.showSuccess) {
      //   this.$message({ message: '换肤成功', type: 'success' })
      // } else {
      //   this.showSuccess = true
      // }
    }
  },
  methods: {
    handleSelectTheme() {
    }
  }
}
</script>

<style>
.theme-picker .el-color-picker__trigger {
  vertical-align: middle;
}

.theme-picker-dropdown .el-color-dropdown__link-btn {
  display: none;
}
</style>
