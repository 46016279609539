<template>

  <div class="login-wrap">
    <div class="ms-login">
      <el-row>
        <el-col :span="24">
          <div style="margin-bottom:10px;width: 100%">
            <label style="font-size:120%;">上海普陀区社会福利院</label>
          </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <div style="width: 100%">
            <img src="@/assets/logo.png" style="width: 100%;height: 100%;border-radius: 0px;"/>
          </div>
        </el-col>
        <el-col :span="12">
          <div style="width: 100%;">
            <el-tabs v-model="tabPosition" type="border-card" stretch @tab-click="handleClick" class="login-tabs">

              <el-tab-pane label="手机号" name="mobile">
                <el-form :model="loginForm" :rules="fieldRules" ref="mobile" class="ms-content">
                  <el-form-item prop="username">
                    <el-input v-model.trim="loginForm.mobile" placeholder="请输入手机号" size="small"></el-input>
                  </el-form-item>
                  <el-form-item prop="password">
                    <el-input type="password" placeholder="请输入密码" size="small" v-model.trim="loginForm.password" show-password ></el-input>
                  </el-form-item>
                  <el-form-item prop="captcha">
                    <el-col :span="12">
                      <el-input placeholder="请输入验证码" size="small" v-model.trim="loginForm.captcha"></el-input>
                    </el-col>
                    <el-col :span="12">
                      <el-image style="margin-left:5%;width: 95%;height:100%" :src="loginForm.src" @click="refreshCaptcha()"></el-image>
                    </el-col>
                  </el-form-item>
                  <el-form-item class="login-btn">
                    <el-button type="primary" :disabled="!loginForm.mobile || !loginForm.password || !loginForm.captcha" @click.native.prevent="login" :loading="loading">登 录</el-button>
                  </el-form-item>
                </el-form>
              </el-tab-pane>

              <el-tab-pane label="企 微" name="weichat" class="weichat-pane">
                <span id="wx_qrcode" class="weichat-code"></span>
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="ms_icp">
      <label>Copyright©上海普陀区社会福利院 </label>
      <a href="" target="_blank"> 沪ICP备20011020号</a>
    </div>
  </div>

</template>

<script>
import { mapState } from 'vuex'
import Cookies from "js-cookie"
import ThemePicker from "@/components/ThemePicker"
import {wxwechatLoginRedirectUrl} from "../utils/global";
import {wechatloginstate} from "../http/modules/login";
import testData from '../mock/modules/test'
export default {
  name: 'Login',
  components:{
    ThemePicker
  },
  data() {
    return {
      tabPosition: "mobile",
      loading: false,
      loginForm: {
        mobile: '',
        password: '',
        captcha:'',
        src: '',
        uuid: '',
      },
      fieldRules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
        captcha: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ]
      },
      checked: true
    }
  },
  methods: {
    login() {

      // 用户名和密码登陆
      let localUUID = this.loginForm.uuid

      if (this.loginForm.uuid === '') {
        this.$message({
          message: '验证码获取失败',
          type: 'error'
        })
        return
      }
      else {
        // 清空UUID.
        this.loginForm.uuid = '';
      }

      this.loading = true
      let userInfo = {mobile:this.loginForm.mobile, password:this.loginForm.password, captcha:this.loginForm.captcha, uuid:localUUID}

      //清除历史数据
      Cookies.set('token', '') // 放置token到Cookie
      sessionStorage.setItem('user', '') // 保存用户到本地会话

      this.$api.login.login(userInfo).then((res) => {
        if(res.msg != null) {
          this.$message({
            message: res.msg,
            type: 'error'
          })
          this.generateCaptcha();

        } else {
          Cookies.set('token', res.data.token) // 放置token到Cookie
          sessionStorage.setItem('user', res.data.principal) // 保存用户到本地会话
          sessionStorage.setItem('currentOrgId', res.data.currentOrgId) // 保存机构到本地会话
          this.$store.commit('menuRouteLoaded', false) // 要求重新加载导航菜单
          // 登录成功，跳转到主页
          // 清空 stateUuid
          Cookies.set('state', '');
          self.location = this.global.baseLoginUrl;
        }
        this.loading = false
      }).catch((res) => {
        this.$message({
          message: res.message,
          type: 'error'
        })

        this.generateCaptcha();
      });
    },
    //异步生成验证码
    generateCaptcha:function () {
      // 生成验证码
      this.loginForm.captcha = '';
      this.$api.login.captcha().then((res) => {
        if (res.msg != null) {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        } else {
          //获取图片
          this.loginForm.src = "data:image/jpeg;base64," + res.data.pic;
          this.loginForm.uuid = res.data.uuid;
        }
      }).catch((res) => {
        this.$message({
          message: res.message,
          type: 'error'
        })
      });
    },

    //监听回车键执行事件
    keydown(e) {
      if(e.keyCode === 13) {
        this.login()
      }
    },
    // 初始化测试数据
    initialTestData:function () {
      let localTestData = testData.testData.data;
      //alert(localTestData.details.remoteAddress);

    },

    // 初始化登陆页面,同步请求
    initialLoginPage:async function () {
      // TODO: stone 2023.05.13. cookie 过期时间的处理待完善，不同浏览器有不同的处理方式。
      // 从cookie中获取stateuuid
      let stateUuid = Cookies.get('state');

      if ((stateUuid=== undefined) || (stateUuid === null) || (stateUuid === '')) {
        // 从后端获取当前会话的uuid
        // 如果没有或者过期了，那么后端就重新生成
        // 调用后台接口获取微信登录的state的uuid.
        await this.$api.login.wechatstate().then((res) => {
          if (res.msg != null) {
            this.$message({
              message: res.msg,
              type: 'error'
            })
          } else {
            stateUuid = res.data;

            //设置cookie的uuid.即state.
            //每一次都设置最新的，旧的就让它过期吧
            Cookies.set('state', stateUuid);

          }
        }).catch((res) => {
          this.$message({
            message: res.message,
            type: 'error'
          })
        });
      }

      // 然后判断当前的状态，是第一次登陆还是微信跳转登陆
      let loginState = '';
      let params = {uuid:stateUuid};
      await this.$api.login.wechatloginstate(params).then((res) => {
        if (res.msg != null) {
          this.$message({
            message: res.msg,
            type: 'error'
          });
          //发生错误时，要清除已有的cookie.
          Cookies.set("state",'');
          Cookies.set('token', '') // 放置token到Cookie

        } else {
          loginState = res.data;
        }
      }).catch((res) => {
        this.$message({
          message: res.message,
          type: 'error'
        });
        //发生错误时，要清除已有的cookie.
        Cookies.set("state",'');
        Cookies.set('token', '') // 放置token到Cookie
      });

      if (loginState != '') {

        // TODO: stone 2023.05.01. hard code, loginState 0 第一次登陆
        if (loginState == '0') {
          // 第一次登陆
          // 一个页面处理两种逻辑

          // 生成企业微信二维码
          if (stateUuid != '') {
            var wwLogin = new WwLogin({
              "id": this.global.wxwechatLoginId,
              "appid": this.global.wxwechatLoginAppId,
              "agentid": this.global.wxwechatLoginAgentId,
              "redirect_uri": this.global.wxwechatLoginRedirectUrl,
              "state": stateUuid,
              "href": this.global.wxwechatLoginCssHref,
              "lang": this.global.wxwechatLoginLang,
            });
          }
          else {
            this.$message({
              message: '生成企业微信二维码失败，请联系管理员',
              type: 'error'
            })
          }

          // 同步生成验证码
          this.loginForm.captcha = '';
          await this.$api.login.captcha().then((res) => {
            if (res.msg != null) {
              this.$message({
                message: res.msg,
                type: 'error'
              })
            } else {
              //获取图片
              this.loginForm.src = "data:image/jpeg;base64," + res.data.pic;
              this.loginForm.uuid = res.data.uuid;
            }
          }).catch((res) => {
            this.$message({
              message: res.message,
              type: 'error'
            })
          });
        }
        else
          // 微信跳转
        {
          //从cookie中获取uuid.即state.
          let localState = Cookies.get('state');

          // 直接读取token进行登陆
          //开始解析.
          this.loading = true
          // userInfo全部为空.
          let userInfo = {mobile: '', password: '', captcha: '', uuid: '', wechatState: localState};

          //清除历史数据
          Cookies.set('token', '') // 放置token到Cookie
          sessionStorage.setItem('user', '') // 保存用户到本地会话

          await this.$api.login.login(userInfo).then((res) => {
            if (res.msg != null) {
              // 清空 stateUuid
              Cookies.set('state', '');
              this.$message({
                message: res.msg,
                type: 'error'
              })
            } else {
              Cookies.set('token', res.data.token) // 放置token到Cookie
              sessionStorage.setItem('user', res.data.principal) // 保存用户到本地会话
              this.$store.commit('menuRouteLoaded', false) // 要求重新加载导航菜单

              // 登录成功，跳转到主页
              // 清空 stateUuid
              Cookies.set('state', '');
              self.location = this.global.baseLoginUrl;
            }
            this.loading = false
          }).catch((res) => {
            // 清空 stateUuid
            Cookies.set('state', '');
            this.$message({
              message: res.message,
              type: 'error'
            })
          });
        }
      }
      else {
        this.$message({
          message: '获取状态失败,请刷新页面重试',
          type: 'error'
        })
      }
    },
    reset() {
      this.$refs.loginForm.resetFields()
    },
    // 切换主题
    onThemeChange: function(themeColor) {
      this.$store.commit('setThemeColor', themeColor)
    },
    handleClick() {

    }
  },
  mounted() {
    // 获取测试数据
    this.initialTestData();

    //初始化页面
    this.initialLoginPage();

    //绑定键盘监听事件
    window.addEventListener("keydown", this.keydown)
  },
  destroyed() {
    //销毁监听事件
    window.removeEventListener("keydown", this.keydown, false)
  },
  computed:{
    ...mapState({
      themeColor: state=>state.app.themeColor
    })
  }
}
</script>

<style lang="scss" scoped>
.login-wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  // background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url('../assets/index_bg.png');
}
.ms-login {
  position: absolute;
  left: 15%;
  top: 50%;
  width: 450px;
  height: 300px;
  margin: -190px 0 0 -175px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.7);
  overflow: hidden;
  padding: 20px;
}
.login-tabs {
  // margin-bottom: 20px;
  text-align: center;
}
.weichat-pane {
  //background: red;
  height: 80%;
  width: 80%;
  text-align: center;
  // height: 260px;
  // padding: 0px;
  // margin: 0px;
}
.weichat-code {
  // padding: 0px;
  // margin: 0px;
  text-align: center;
  display:block;
  //background: yellow;
}
.ms-content .el-form-item{
  margin: 0px;
  padding: 0px;
}
.login-btn {
  text-align: center;
  margin-top: 10px;
}
.login-btn button {
  width: 100%;
  height: 36px;
  margin-bottom: 10px;
}
.ms_icp {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translate(-50%,-50%);
}

</style>
