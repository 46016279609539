import axios from '../axios'

/*
 * 设备管理模块
 */

// 保存
export const save = (data) => {
    return axios({
        url: '/device/save',
        method: 'post',
        data
    })
}
// 删除
export const batchDelete = (data) => {
    return axios({
        url: '/device/delete',
        method: 'post',
        data
    })
}
// 分页查询
export const findPage = (data) => {
    return axios({
        url: '/device/findPage',
        method: 'post',
        data
    })
}
// 导出Excel设备信息
export const exportDeviceExcelFile = (data) => {
    return axios({
        url: '/device/exportDeviceExcelFile',
        method: 'post',
        data
    })
}
// 查找设备的菜单权限标识集合
export const findPermissions = (params) => {
    return axios({
        url: '/device/findPermissions',
        method: 'get',
        params
    })
}
// 根据设备名查找
export const findByName = (params) => {
    return axios({
        url: '/device/findByName',
        method: 'get',
        params
    })
}
