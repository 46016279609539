import axios from '../axios'

/*
 * 用户管理模块
 */

// 保存
export const save = (data) => {
    return axios({
        url: '/user/save',
        method: 'post',
        data
    })
}
// 删除
export const batchDelete = (data) => {
    return axios({
        url: '/user/delete',
        method: 'post',
        data
    })
}
// 分页查询
export const findPage = (data) => {
    return axios({
        url: '/user/findPage',
        method: 'post',
        data
    })
}

// 查找有当前机构访问权限的所有用户
export const findAllUserCurrentOrg = () => {
  return axios({
    url: '/user/findAllUserCurrentOrg',
    method: 'get'
  })
}

// 导出Excel用户信息
export const exportUserExcelFile = (data) => {
    return axios({
        url: '/user/exportUserExcelFile',
        method: 'post',
        data
    })
}
// 查找用户的菜单权限标识集合
export const findPermissions = (params) => {
    return axios({
        url: '/user/findPermissions',
        method: 'get',
        params
    })
}
// 根据用户名查找
export const findByName = (params) => {
    return axios({
        url: '/user/findByName',
        method: 'get',
        params
    })
}

// 根据用户编号查找
export const findById = (params) => {
  return axios({
    url: '/user/findById',
    method: 'get',
    params
  })
}

// 更新用户密码
export const updatePassword = (params) => {
    return axios({
        url: '/user/updatePassword',
        method: 'get',
        params
    })
}

// 根据用户名查找当前用户机构,后端还会通过token获取.
export const findByUserName = (params) => {
  return axios({
    url: '/org/findByUserName',
    method: 'get',
    params
  })
}

// 后台返回当前用户默认的机构.
export const findUserCurrentOrgs = () => {
  return axios({
    url: '/user/findUserCurrentOrgs',
    method: 'get'
  })
}

// 保存当前机构,刷新token.
export const refreshtokenOrgAndFindUserCurrentOrgs = (params) => {
  return axios({
    url: '/user/refreshtokenOrgAndFindUserCurrentOrgs',
    method: 'get',
    params
  })
}

// 保存当前客户,刷新token.
export const refreshtokenCustomer = (params) => {
  return axios({
    url: '/user/refreshtokenCustomer',
    method: 'get',
    params
  })
}
