<template>
  <div class="page-container">
	<!--工具栏-->
	<div class="toolbar" style="padding:10px;">
		<el-form :inline="true" :model="filters" :size="size" align="left">

      <el-form-item label="照护区域" prop="careAreaId">
        <el-select v-model="filters.careAreaId"  placeholder="请选择照护区域"
                   style="width: 100%;" filterable clearable>
          <el-option v-for="item in careAreaFilters" :key="item.id"
                     :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="工单类型" prop="customerName">
        <el-select v-model="filters.workordertypeDictId"  placeholder="请选择工单类型"
                   style="width: 100%;" filterable clearable>
          <el-option v-for="item in workordertypes" :key="item.id"
                     :label="item.label" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="长者姓名" prop="customerName">
        <el-select v-model="filters.customerId"  placeholder="请选择长者"
                   style="width: 100%;" filterable clearable>
          <el-option v-for="item in customers" :key="item.id"
                     :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <!-- <el-form-item >
        <el-switch
          v-model="filters.flagExecute"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-text="未执行"
          inactive-text="全部" @change="findPage(null)">
        </el-switch>
      </el-form-item> -->

			<el-form-item>
				<kt-button icon="fa fa-search" :label="$t('action.search')" perms="workorder:workorder:view" type="primary" @click="findPage(null)"/>
			</el-form-item>
      <el-form-item>
        <kt-button icon="fa fa-plus" label="紧急工单" perms="workorder:workorder:add" type="primary" @click="handleAdd"/>
      </el-form-item>
      <el-form-item>
        <kt-button icon="fa fa-edit" :label="$t('action.nessWorkorder')" perms="workorder:workorder:view" type="primary" @click="generateNessWorkorder()"/>
      </el-form-item>

      <!--
      <el-form-item>
        <kt-button icon="fa fa-search" label="查询有工单客户" perms="workorder:workorder:view" type="primary" @click="findPageUserWithWorkorder(null)"/>
      </el-form-item>
      -->

		</el-form>
	</div>
	<!-- <div class="toolbar" style="float:right;padding-top:10px;padding-right:15px;">
		<el-form :inline="true" :size="size">
			<el-form-item>
				<el-button-group>
				<el-tooltip content="刷新" placement="top">
					<el-button icon="fa fa-refresh" @click="findPage(null)"></el-button>
				</el-tooltip>
				<el-tooltip content="列显示" placement="top">
					<el-button icon="fa fa-filter" @click="displayFilterColumnsDialog"></el-button>
				</el-tooltip>
				</el-button-group>
			</el-form-item>
		</el-form>

		表格显示列界面
		<table-column-filter-dialog ref="tableColumnFilterDialog" :columns="columns"
			@handleFilterColumns="handleFilterColumns">
		</table-column-filter-dialog>

	</div> -->

	<!--表格内容栏-->
	<kt-table permsEdit="workorder:workorder:edit" permsDelete="workorder:workorder:delete"
		:data="pageResult" :columns="columns" :hasExtOperation="true" :hasEnableEdit="false" :extBtnLabel="$t('action.workorderExecute')"
		@findPage="findPage" @handleEdit="handleEdit" @handleDelete="handleDelete" @handleExtEdit="handleExecute">
	</kt-table>

    <!--新增照护工单，选择客户界面-->
    <el-dialog title="新增照护工单" width="400px" :visible.sync="dialogWorkorderVisible" :close-on-click-modal="false">
      <el-form :model="customerFormData" label-width="80px" :rules="customerFormDataRules" ref="customerForm" :size="size"
               label-position="right">
        <el-form-item label="长者姓名" prop="customerId">
          <el-select v-model="customerFormData.customerId"  placeholder="请选择客户"
                     style="width: 100%;" filterable>
            <el-option v-for="item in customers" :key="item.id"
                       :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button :size="size" @click.native="dialogWorkorderVisible = false">{{$t('action.cancel')}}</el-button>
        <el-button :size="size" type="primary" @click.native="submitCustomerForm" :loading="editCustomerLoading">{{$t('action.confirm')}}</el-button>
      </div>
    </el-dialog>

    <!-- 新增修改界面 -->
    <el-dialog :title="!workorderFormData.id ? '新增' : '修改'" width="400px" :visible.sync="dialogVisible" :close-on-click-modal="false">
      <el-form :model="workorderFormData" :rules="workorderFormDataRules" ref="workorderForm" @keyup.enter.native="submitForm()"
               label-width="80px" :size="size" style="text-align:left;">
        <el-form-item label="ID" prop="id" v-if="false">
          <el-input v-model="workorderFormData.id" :disabled="true" auto-complete="off"></el-input>
        </el-form-item>

        <el-form-item label="长者姓名" prop="customerId">
          <el-select v-model="workorderFormData.customerId"  placeholder="请选择长者"
                     style="width: 100%;" filterable>
            <el-option v-for="item in allCustomers" :key="item.id"
                       :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="发生时间" prop="occurDatetime">
          <el-date-picker v-model="workorderFormData.occurDatetime"
                          align="right"
                          type="datetime"
                          style="width: 100%;"
                          placeholder="发生时间"
                          :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="工单描述" prop="urgentDesc">
          <el-input v-model="workorderFormData.urgentDesc" auto-complete="off" ></el-input>
        </el-form-item>

        <el-form-item label="推送员工" prop="workorderUrgentUsers" >
          <el-select v-model="workorderFormData.workorderUrgentUsers" multiple placeholder="请选择员工"
                     style="width: 100%;">
            <el-option v-for="item in users" :key="item.id"
                       :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

      </el-form>


      <span slot="footer" class="dialog-footer">
        <el-button :size="size"  @click="dialogVisible = false">{{$t('action.cancel')}}</el-button>
        <el-button :size="size"  type="primary" @click="submitForm()" :loading="editLoading">{{$t('action.confirm')}}</el-button>
      </span>

    </el-dialog>

  </div>
</template>

<script>
import PopupTreeInput from "@/components/PopupTreeInput"
import KtTable from "@/views/Core/KtTableWorkorder"
import KtButton from "@/views/Core/KtButton"
import TableColumnFilterDialog from "@/views/Core/TableColumnFilterDialog"
import { format } from "@/utils/datetime"
import {dateFormatUTC8} from "../../utils/datetime";
import {saveGenerateUrgent} from "../../http/modules/workorderbase";
import Vue from "vue";
export default {
	components:{
		PopupTreeInput,
		KtTable,
		KtButton,
		TableColumnFilterDialog
	},
	data() {
		return {
			size: 'small',
			filters: {
        careAreaId: '',
				customerId:'',
        workordertypeDictId:'',
        flagExecute:true
			},
			columns: [],
			filterColumns: [],
			pageRequest: { pageNum: 1, pageSize: 10 },
			pageResult: {},

      editCustomerLoading: false,
      customerFormDataRules: {
        customerId: [
          { required: true, message: '请输入客户名', trigger: 'blur' }
        ]
      },
      dialogWorkorderVisible: false, // 工单对话框是否显示;
      customerFormData: {
        customerId:''
      },

			operation: false, // true:新增, false:编辑
			dialogVisible: false, // 新增编辑界面是否显示
			editLoading: false,
			workorderFormDataRules: {
				customerId: [
					{ required: true, message: '请选择长者', trigger: 'blur' }
				],
        occurDatetime: [
          { required: true, message: '请输入发生时间', trigger: 'blur' }
        ],
        workorderUrgentUsers: [
          { required: true, message: '请选择员工', trigger: 'blur' }
        ]
			},

      pickerOptions: {
        disabledDate(time) {
          //return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },

      // 新增编辑界面数据
			workorderFormData: {
				id: 0,
				customerId: '',
        occurDatetime:'',
        urgentDesc:'',
        workorderUrgentUsers:[]
			},
      allCustomers: [],
			customers: [],
      users:[],

      careAreaFilters: [],
      workordertypes: [],
      manualFlags: [],

      dialogExecuteVisible: false, // 执行界面是否显示
		}
	},
	methods: {
    // 新增照护工单
    generateNessWorkorder: function () {
      this.dialogWorkorderVisible = true;
    },

    // 提交客户表单
    submitCustomerForm: function () {
      this.$refs.customerForm.validate((valid) => {
        if (valid) {
          this.$confirm('确认提交吗？', '提示', {}).then(() => {
            this.editCustomerLoading = true

            if ((this.customerFormData.customerId !== null) && (this.customerFormData.customerId !== ''))
            {
              // 获取当前客户的有效的照护计划.
              // 需要通过此方式来指定参数.
              let baseParams = {customerId:this.customerFormData.customerId}

              this.$api.customerservicecontentbase.findByCustomerId(baseParams).then((res) => {
                if (res.code === 200) {
                  // 然后推送按需工单生成.
                  if (res.data !== null) {

                    this.editCustomerLoading = false;
                    this.dialogWorkorderVisible = false;

                    this.$router.push({
                      path: '/care/customerservicecontentdetailness',
                      query: {customerservicecontentId: res.data.id}
                    })
                  }
                  else {
                    this.$message({message: '该客户当前时间没有照护计划' , type: 'error'})
                    this.editCustomerLoading = false;
                    this.dialogWorkorderVisible = false;
                    return;
                  }
                } else {
                  this.$message({message: '该客户当前时间没有照护计划' , type: 'error'})
                  this.editCustomerLoading = false;
                  this.dialogWorkorderVisible = false;
                  return;
                }
              });
            }
            else {
              this.$message({message: '新增照护工单失败,没有选择客户 ' , type: 'error'})
              this.editCustomerLoading = false;
              this.dialogWorkorderVisible = false;
              return;
            }
          })
        }
      })
    },


    // 获取分页数据
		findPage: function (data) {
			if(data != null) {
				this.pageRequest = data.pageRequest
			}
      this.pageRequest.csAssetTypes = {};
			if ((this.filters.customerId !== null) && (this.filters.customerId !== ''))
      {
        this.pageRequest.csAssetTypes = {flagExecute:this.filters.flagExecute,customerId:this.filters.customerId}
      }
      else {
        this.pageRequest.csAssetTypes = {flagExecute:this.filters.flagExecute}
      }

      if ((this.filters.workordertypeDictId !== null) && (this.filters.workordertypeDictId !== ''))
      {
        Vue.set(this.pageRequest.csAssetTypes,'workorderTypeDictId',this.filters.workordertypeDictId);
      }

      if ((this.filters.careAreaId !== null) && (this.filters.careAreaId !== ''))
      {
        Vue.set(this.pageRequest.csAssetTypes,'careAreaId',this.filters.careAreaId);
      }

			this.$api.workorderbase.findCurrentUserPage(this.pageRequest).then((res) => {
        if (res.code === 200) {
          this.pageResult = res.data
          if (this.filters.flagExecute) {
            this.findCustomersWithExecuteWorkorder();
          }
          else {
            this.findCustomersWithWorkorder()
          }
        } else {
          //2024.09.02. stone zhao,去除错误提示.
          //this.$message({message: '操作失败, ' + res.msg, type: 'error'})
        }
			}).then(data!=null?data.callback:'')
		},

    // 加载区域信息
    findCareAreaFilters: function () {
      this.$api.carearea.findAllCareAreas().then((res) => {
        this.careAreaFilters =  res.data
      })
    },

    // 加载工单类型
    findWorkorderTypes: function () {
		  // type 固定为 "workorder-type".
      let params = {type:"workorder-type"}
      this.$api.dict.findByType(params).then((res) => {
        // 加载工单类型
        this.workordertypes = res.data
      })
    },

    // 加载手动执行标记字典信息
    findManualFlags: function () {
      let params = {type:"manual-flag"}
      this.$api.dict.findByType(params).then((res) => {
        this.manualFlags =  res.data
      })
    },

    // 加载有工单的客户信息
    findCustomersWithWorkorder: function () {
      //TODO: 2023.02.28. hard code . 2000客户，未来考虑分页. stone
      let params =  { pageNum: 1, pageSize: 2000 }
      //params.csAssetTypes = {workorderTypeDictId:55}
      this.$api.workorderbase.findPagePureUserWithWorkorder(params).then((res) => {
        this.customers =  res.data.content
      })
    },

    // 加载有未执行工单的客户信息
    findCustomersWithExecuteWorkorder: function () {
      //TODO: 2023.02.28. hard code . 2000客户，未来考虑分页. stone
      let params =  { pageNum: 1, pageSize: 2000 }
      //params.csAssetTypes = {workorderTypeDictId:55}
      this.$api.workorderbase.findPagePureUserWithExecuteWorkorder(params).then((res) => {
        this.customers =  res.data.content
      })
    },

    // 加载所有客户信息
    findAllCustomers: function () {
      this.$api.customer.findAll().then((res) => {
        this.allCustomers =  res.data
      })
    },


    // 加载用户信息
    findUsers: function () {
      this.$api.user.findAllUserCurrentOrg().then((res) => {
        // 加载用户集合
        this.users = res.data
      })
    },

		// 批量删除
		handleDelete: function (data) {
			this.$api.workorderbase.batchDelete(data.params).then(data!=null?data.callback:'')
		},

    // 执行
    handleExecute: function (data) {

      if ((data.row.executeBy === '') || (data.row.executeBy === null) || (data.row.executeBy === undefined) ) {


        this.$confirm('确认执行吗？', '执行', {}).then(() => {
          let params = {
            "id": data.row.id,
            "executeDatetime": new Date()
          }

          this.$api.workorderbase.save(params).then((res) => {
            if (res.code == 200) {
              this.$message({message: '操作成功', type: 'success'})
              this.findPage(null)
            } else {
              this.$message({message: '操作失败, ' + res.msg, type: 'error'})
            }
          })
        })
      }
      else {
        this.$message({message: '工单已执行 ！' , type: 'error'})
      }
    },

		// 显示新增界面
		handleAdd: function () {
			this.dialogVisible = true
			this.operation = true
      this.workorderFormData = {
        id: 0,
        customerId:'',
        occurDatetime:'',
        urgentDesc:'',
        workorderUrgentUsers:[]
      }
		},

		// 显示编辑界面
		handleEdit: function (params) {
			this.dialogVisible = true
			this.operation = false
			this.workorderFormData = Object.assign({}, params.row)
		},

		// 提交表单
		submitForm: function () {
			this.$refs.workorderForm.validate((valid) => {
				if (valid) {
					this.$confirm('确认提交吗？', '提示', {}).then(() => {
						this.editLoading = true
						let params = Object.assign({}, this.workorderFormData)

            // 紧急工单推送员工.
            let workorderUrgentUsers = []
            for (let i=0,len = params.workorderUrgentUsers.length;i<len;i++) {

              let obj = {};
              obj = this.users.find((item)=>{
                return item.id === params.workorderUrgentUsers[i];//筛选出匹配数据
              });

              console.log(obj.id)
              console.log(obj.name)

              let workorderUrgentUser = {
                workorderUrgentId : params.id,
                userId : params.workorderUrgentUsers[i],
                userName: obj.name
              }
              workorderUrgentUsers.push(workorderUrgentUser)
            }
            params.workorderUrgentUsers = workorderUrgentUsers

						this.$api.workorderbase.saveGenerateUrgent(params).then((res) => {
							this.editLoading = false
							if(res.code === 200) {
								this.$message({ message: '操作成功', type: 'success' })
								this.dialogVisible = false
								this.$refs['workorderForm'].resetFields()
							} else {
								this.$message({message: '操作失败, ' + res.msg, type: 'error'})
							}
							this.findPage(null)
						})
					})
				}
			})
		},
    // 时间格式化
    dateFormat: function (row, column, cellValue, index){
      return format(row[column.property])
    },
    // 处理表格列过滤显示
    displayFilterColumnsDialog: function () {
      this.$refs.tableColumnFilterDialog.setDialogVisible(true)
    },
		// 处理表格列过滤显示
    handleFilterColumns: function (data) {
      this.filterColumns = data.filterColumns
      this.$refs.tableColumnFilterDialog.setDialogVisible(false)
    },
    // 处理表格列过滤显示
    initColumns: function () {
      this.columns = [
        // {prop:"id", label:"编 号", minWidth:80},
        {prop:"careAreaName", label:"照护区域", minWidth:120},
        {prop:"customerName", label:"长者姓名", minWidth:120},
        {prop:"roomFullName", label:"床位号", minWidth:120},
        {prop:"workorderTypeDictLabel", label:"工单类型", minWidth:120},
        {prop:"workorderDesc", label:"工单描述", minWidth:200},
        {prop:"serviceSpecialName", label:"服务要求", minWidth:180},
        {prop:"manualFlagDictLabel", label:"执行类型", minWidth:120},
        {prop:"planDatetime", label:"计划时间", minWidth:140, formatter:this.dateFormat},
        //{prop:"executeBy", label:"执行人", minWidth:120},
        //{prop:"executeDatetime", label:"执行时间", minWidth:140, formatter:this.dateFormat},
        // {prop:"orgName", label:"机 构", minWidth:120},
        // {prop:"createBy", label:"创建人", minWidth:120},
        // {prop:"createTime", label:"创建时间", minWidth:140, formatter:this.dateFormat}
        // {prop:"lastUpdateBy", label:"更新人", minWidth:100},
        // {prop:"lastUpdateTime", label:"更新时间", minWidth:120, formatter:this.dateFormat}
      ]
      this.filterColumns = JSON.parse(JSON.stringify(this.columns));
    }
	},
	mounted() {
		this.initColumns();
    this.findAllCustomers();
    this.findWorkorderTypes();
    this.findManualFlags();
    this.findUsers();
    this.findCareAreaFilters()
	}
}
</script>

<style scoped>

</style>
