var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "message-panel" }, [
    _c("div", { staticClass: "message-header" }, [
      _vm._v("您有 " + _vm._s(_vm.data.length) + " 条消息")
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "message-content" },
      _vm._l(_vm.data, function(item) {
        return _c("div", { key: item.key, staticClass: "message-item" }, [
          _c("div", { staticClass: "message-avatar" }, [
            _c("img", {
              staticClass: "avatar",
              attrs: { src: require("@/assets/user.png") }
            })
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "sender" }, [
            _vm._v("\n        " + _vm._s(item.sender) + "\n      ")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "time" }, [
            _c("li", { staticClass: "fa fa-clock-o" }),
            _vm._v(" " + _vm._s(item.time) + "\n      ")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "message-cotent" }, [
            _vm._v("\n        " + _vm._s(item.content) + "\n      ")
          ])
        ])
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "message-footer" }, [_vm._v("查看所有消息")])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5aa69d54", { render: render, staticRenderFns: staticRenderFns })
  }
}