var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "site-wrapper site-page--not-found" }, [
    _c("div", { staticClass: "site-content__wrapper" }, [
      _c(
        "div",
        { staticClass: "site-content" },
        [
          _c("h2", { staticClass: "not-found-title" }, [_vm._v("404")]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.$router.go(-1)
                }
              }
            },
            [_vm._v("返回上一页")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "not-found-btn-gohome",
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.$router.push("/")
                }
              }
            },
            [_vm._v("进入待办")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "not-found-desc" }, [
      _vm._v("抱歉！您访问的页面"),
      _c("em", [_vm._v("失联")]),
      _vm._v("啦 ...")
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-61cc3f34", { render: render, staticRenderFns: staticRenderFns })
  }
}