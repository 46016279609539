import axios from '../axios'

/*
 * 消息用户管理模块
 */

// 保存
export const save = (data) => {
    return axios({
        url: '/messageinfouser/save',
        method: 'post',
        data
    })
}

// 查看
export const check = (data) => {
  return axios({
    url: '/messageinfouser/check',
    method: 'post',
    data
  })
}

// 删除
export const batchDelete = (data) => {
    return axios({
        url: '/messageinfouser/delete',
        method: 'post',
        data
    })
}

