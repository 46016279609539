import axios from '../axios'

/*
 * 日历管理基础模块
 */

// 保存
export const save = (data) => {
    return axios({
        url: '/calendarbase/save',
        method: 'post',
        data
    })
}
// 删除
export const batchDelete = (data) => {
    return axios({
        url: '/calendarbase/delete',
        method: 'post',
        data
    })
}

// 分页查询
export const findPage = (data) => {
  return axios({
    url: '/calendarbase/findPage',
    method: 'post',
    data
  })
}

// 查找所有日历
export const findAll = (data) => {
  return axios({
    url: '/calendarbase/findAll',
    method: 'post',
    data
  })
}

// 根据id查询配置
export const findById = (params) => {
  return axios({
    url: '/calendarbase/findById',
    method: 'get',
    params
  })
}
