<template>
  <div>
    <!--表格栏-->
    <el-table :data="data.content" :highlight-current-row="highlightCurrentRow" @selection-change="selectionChange"
          @current-change="handleCurrentChange" v-loading="loading" :element-loading-text="$t('action.loading')" :border="border" :stripe="stripe"
          :show-overflow-tooltip="showOverflowTooltip" :max-height="maxHeight" :size="size" :align="align" style="width:100%;" >
      <el-table-column type="selection" width="55" v-if="showBatchDelete & showOperation"></el-table-column>
      <el-table-column v-for="column in columns" header-align="center" align="center"
        :prop="column.prop" :label="column.label" :width="column.width" :min-width="column.minWidth"
        :fixed="column.fixed" :key="column.prop" :type="column.type" :formatter="column.formatter"
         show-overflow-tooltip=“true”>
        <!-- :sortable="column.sortable==null?true:column.sortable" -->
      </el-table-column>
      <el-table-column :label="$t('action.operation')" width="185" fixed="right" v-if="showOperation" header-align="center" align="center">
        <template slot-scope="scope">
          <!--TODO: stone 2022.10.24. 注意，此处对于按钮的显示使用了hard code，因为是组件传递，还没有想到好的办法来传递控制参数.96: 外出请假已审核; 49:照护计划已审核;153: 照护计划已终止.-->
          <kt-button v-if="hasExtOperation" :disabled="!hasExtOperation || scope.row.executeDatetime != null || scope.row.statusDictId === 96 ||  scope.row.statusDictId === 153" icon="fa fa-edit" :label="extBtnLabel" :perms="permsEdit" :size="size" @click="handleExtEdit(scope.$index, scope.row)" />
          <!-- <kt-button v-if="hasExtOperation" :disabled="!hasExtOperation || scope.row.manualFlagDictId === 53 || scope.row.executeBy != null " icon="fa fa-edit" :label="extBtnLabel" :perms="permsEdit" :size="size" @click="handleExtEdit(scope.$index, scope.row)" /> -->
          <kt-button v-if="hasEnableDelete" :disabled="scope.row.statusDictId === 49 || scope.row.workorderTypeDictLabel === '照护工单' || scope.row.statusDictId === 96 ||  scope.row.statusDictId === 153 || scope.row.executeDatetime != null" icon="fa fa-trash" :label="$t('action.delete')" :perms="permsDelete" :size="size" type="danger" @click="handleDelete(scope.$index, scope.row)" />
        </template>
      </el-table-column>
    </el-table>
    <!--分页栏-->
    <div class="toolbar" style="padding:10px;" v-if="showToolbar">
      <kt-button :label="$t('action.batchDelete')" :perms="permsDelete" :size="size" type="danger" @click="handleBatchDelete()"
        :disabled="this.selections.length===0" style="float:left;" v-if="showBatchDelete & showOperation"/>
      <el-pagination layout="total, prev, pager, next, jumper" @current-change="refreshPageRequest"
        :current-page="pageRequest.pageNum" :page-size="pageRequest.pageSize" :total="data.totalSize" style="float:right;">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import KtButton from "@/views/Core/KtButton"
export default {
  name: 'KtTable',
  components:{
			KtButton
	},
  props: {
    columns: Array, // 表格列配置
    data: Object, // 表格分页数据
    permsEdit: String,  // 编辑权限标识
    permsDelete: String,  // 删除权限标识
    extBtnLabel:String, // 扩展按钮的文本
    hasExtOperation: Boolean, // 是否有详情配置按钮.
    hasEnableEdit: { // 是否允许编辑.
      type:  Boolean,
      default: true // 默认允许编辑.
    },
    hasEnableDelete: { // 是否允许删除.
      type:  Boolean,
      default: true // 默认允许删除.
    },
    size: { // 尺寸样式
      type: String,
      default: 'mini'
    },
    align: {  // 文本对齐方式
      type: String,
      default: 'left'
    },
    maxHeight: {  // 表格最大高度
      type: Number,
      default: 720
    },
    showOperation: {  // 是否显示操作组件
      type: Boolean,
      default: true
    },
    border: {  // 是否显示边框
      type: Boolean,
      default: false
    },
    stripe: {  // 是否显示斑马线
      type: Boolean,
      default: true
    },
    highlightCurrentRow: {  // // 是否高亮当前行
      type: Boolean,
      default: true
    },
    showOverflowTooltip: {  // 是否单行显示
      type: Boolean,
      default: true
    },
    showBatchDelete: {  // 是否显示操作组件
      type: Boolean,
      default: false
    },
    showToolbar: {  // 是否显示操作组件
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      // 分页信息
			pageRequest: {
				pageNum: 1,
        pageSize: 10
      },
      loading: false,  // 加载标识
      selections: [],  // 列表选中列
    }
  },
  methods: {
    // 分页查询
    findPage: function () {
        this.loading = true
        let callback = res => {
          this.loading = false
        }
      this.$emit('findPage', {pageRequest:this.pageRequest, callback:callback})
    },
    // 选择切换
    selectionChange: function (selections) {
      this.selections = selections
      this.$emit('selectionChange', {selections:selections})
    },
    // 选择切换
    handleCurrentChange: function (val) {
      this.$emit('handleCurrentChange', {val:val})
    },
    // 换页刷新
		refreshPageRequest: function (pageNum) {
      this.pageRequest.pageNum = pageNum
      this.findPage()
    },
    // 编辑
		handleEdit: function (index, row) {
      //增加判断.
      if (this.judgeStatus(row) && this.judgeType(row)) {
        this.$emit('handleEdit', {index:index, row:row})
      }
      else {
        this.$message({message: '类型或者状态不允许编辑', type: 'error'})
      }
		},
    // 扩展编辑
		handleExtEdit: function (index, row) {
      this.$emit('handleExtEdit', {index:index, row:row})
		},

    //状态判断.
    judgeStatus: function (row) {
      if (row.statusDictId !== undefined)
      {
        //TODO: 2022.10.20. stone. 对于照护计划，已审核状态.
        return row.statusDictId !== 49;
      }
      else {
        return true;
      }
    },
    //类型判断.
    judgeType: function (row) {
      if (row.workorderTypeDictLabel !== undefined)
      {
        //TODO: 2022.10.20. stone. 对于工单，照护工单不允许删除.
        return row.workorderTypeDictLabel !== '照护工单';
      }
      else {
        return true;
      }
    },

    // 删除
		handleDelete: function (index, row) {
      //增加判断.
      if (this.judgeStatus(row) && this.judgeType(row)) {
        this.delete(row.id)
      }
      else {
        this.$message({message: '类型或者状态不允许删除', type: 'error'})
      }
		},
		// 批量删除
		handleBatchDelete: function () {
			let ids = this.selections.map(item => item.id).toString()
			this.delete(ids)
		},
		// 删除操作
		delete: function (ids) {
			this.$confirm('确认删除选中记录吗？', '提示', {
				type: 'warning'
			}).then(() => {
				let params = []
				let idArray = (ids+'').split(',')
				for(var i=0; i<idArray.length; i++) {
					params.push({'id':idArray[i]})
        }
        this.loading = true
        let callback = res => {
          if(res.code == 200) {
            this.$message({message: '删除成功', type: 'success'})
            this.findPage()
          } else {
            this.$message({message: '操作失败, ' + res.msg, type: 'error'})
          }
          this.loading = false
        }
        this.$emit('handleDelete', {params:params, callback:callback})
			}).catch(() => {
			})
		}
  },
  mounted() {
    this.refreshPageRequest(1)
  }
}
</script>

<style scoped>

</style>
