import axios from '../axios'

/*
 * 报警清单模块
 */

// 分页查询
export const findPage = (data) => {
    return axios({
        url: '/devicemessage/findPage',
        method: 'post',
        data
    })
}

// televic分页查询
export const findTelevicPage = (data) => {
  return axios({
    url: '/devicemessage/findTelevicPage',
    method: 'post',
    data
  })
}

// 连接mqtt服务器
export const mqttConnect = () => {
  return axios({
    url: '/devicemessage/connectMqttServer',
    method: 'post'
  })
}

