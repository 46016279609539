import axios from '../axios'

/*
 * 部门管理模块
 */

// 保存
export const save = (data) => {
    return axios({
        url: '/post/save',
        method: 'post',
        data
    })
}
// 删除
export const batchDelete = (data) => {
    return axios({
        url: '/post/delete',
        method: 'post',
        data
    })
}
// 分页查询
export const findPage = (data) => {
    return axios({
        url: '/post/findPage',
        method: 'post',
        data
    })
}
// 查询全部岗位
export const findAll = (data) => {
  return axios({
    url: '/post/findAll',
    method: 'post',
    data
  })
}
// 根据岗位名查找
export const findByName = (params) => {
    return axios({
        url: '/post/findByName',
        method: 'get',
        params
    })
}
