var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-tooltip",
    { attrs: { effect: "dark", content: _vm.label, placement: "top-start" } },
    [
      _c(
        "el-button",
        {
          attrs: {
            size: _vm.size,
            type: _vm.type,
            icon: _vm.icon,
            loading: _vm.loading,
            disabled: !_vm.hasPerms(_vm.perms)
          },
          on: { click: _vm.handleClick }
        },
        [
          !_vm.hideLabel
            ? [_vm._v("\n      " + _vm._s(_vm.label) + "\n    ")]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-68f3f294", { render: render, staticRenderFns: staticRenderFns })
  }
}