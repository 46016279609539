import axios from '../axios'

/*
 * 护理计划基础模块
 */

// 保存
export const save = (data) => {
    return axios({
        url: '/customerservicecontentbase/save',
        method: 'post',
        data
    })
}
// 删除
export const batchDelete = (data) => {
    return axios({
        url: '/customerservicecontentbase/delete',
        method: 'post',
        data
    })
}

// 分页查询
export const findPage = (data) => {
  return axios({
    url: '/customerservicecontentbase/findPage',
    method: 'post',
    data
  })
}

// 根据照护计划编号，单条查询
export const findByBaseId = (params) => {
  return axios({
    url: '/customerservicecontentbase/findByBaseId',
    method: 'get',
    params
  })
}

// 根据客户编号，单条查询当前日期有效的照护计划，应该只有一条.
export const findByCustomerId = (params) => {
  return axios({
    url: '/customerservicecontentbase/findByCustomerId',
    method: 'get',
    params
  })
}

// 根据客户编号，单条查询当前日期之后有效的照护计划，可能多条.
export const findByCustomerIdBehindNow = (params) => {
  return axios({
    url: '/customerservicecontentbase/findByCustomerIdBehindNow',
    method: 'get',
    params
  })
}

