var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      staticClass: "org",
      attrs: { placeholder: "机构" },
      on: { change: _vm.onChangeOrg },
      model: {
        value: _vm.cOrg.id,
        callback: function($$v) {
          _vm.$set(_vm.cOrg, "id", $$v)
        },
        expression: "cOrg.id"
      }
    },
    _vm._l(_vm.orgs, function(item) {
      return _c("el-option", {
        key: item.id,
        attrs: { label: item.name, value: item.id }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3a621399", { render: render, staticRenderFns: staticRenderFns })
  }
}