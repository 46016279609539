/* script */
var __vue_script__ = null
/* template */
var __vue_render__, __vue_static_render_fns__
/* template functional */
var __vue_template_functional__ = false
/* styles */
var __vue_styles__ = null
/* scopeId */
var __vue_scopeId__ = null
/* moduleIdentifier (server only) */
var __vue_module_identifier__ = null
import normalizeComponent from "!../../node_modules/vue-loader/lib/runtime/component-normalizer"
var Component = normalizeComponent(
  __vue_script__,
  __vue_render__,
  __vue_static_render_fns__,
  __vue_template_functional__,
  __vue_styles__,
  __vue_scopeId__,
  __vue_module_identifier__
)
Component.options.__file = "src\\views\\Blank.vue"

export default Component.exports
