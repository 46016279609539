import axios from '../axios'

/*
 * 交接班扩展模块
 */

// 保存
export const save = (data) => {
    return axios({
        url: '/careshiftext/save',
        method: 'post',
        data
    })
}
// 删除
export const batchDelete = (data) => {
    return axios({
        url: '/careshiftext/delete',
        method: 'post',
        data
    })
}
// 根据baseid查询扩展配置
export const findByBaseId = (params) => {
    return axios({
        url: '/careshiftext/findByBaseId',
        method: 'get',
        params
    })
}
