import axios from '../axios'

/*
 * 工单管理基础模块
 */

// 保存
export const save = (data) => {
    return axios({
        url: '/workorderbase/save',
        method: 'post',
        data
    })
}
// 删除
export const batchDelete = (data) => {
    return axios({
        url: '/workorderbase/delete',
        method: 'post',
        data
    })
}

// 根据照护计划编号删除
export const deleteByCustomerServiceContentBaseId = (params) => {
  return axios({
    url: '/workorderbase/deleteByCustomerServiceContentBaseId',
    method: 'get',
    params
  })
}

// 分页查询工单统计.
export const findUserWorkorderStaticsPage = (data) => {
  return axios({
    url: '/workorderbase/findUserWorkorderStaticsPage',
    method: 'post',
    data
  })
}

// 分页查询工单统计.获取某个月的每一天的详情.
export const findUserWorkorderDayStaticsPage = (data) => {
  return axios({
    url: '/workorderbase/findUserWorkorderDayStaticsPage',
    method: 'post',
    data
  })
}

// 分页查询
export const findPage = (data) => {
  return axios({
    url: '/workorderbase/findPage',
    method: 'post',
    data
  })
}

// 根据客户信息分页查询
export const findCurrentUserPage = (data) => {
  return axios({
    url: '/workorderbase/findCurrentUserPage',
    method: 'post',
    data
  })
}

// 查询有工单的客户.带上工单类型.
export const findPageUserWithWorkorder = (data) => {
  return axios({
    url: '/workorderbase/findPageUserWithWorkorder',
    method: 'post',
    data
  })
}

// 查询有未执行工单的客户.带上工单类型.
export const findPageUserWithExecuteWorkorder = (data) => {
  return axios({
    url: '/workorderbase/findPageUserWithExecuteWorkorder',
    method: 'post',
    data
  })
}

// 查询有工单的客户.不带工单类型.
export const findPagePureUserWithWorkorder = (data) => {
  return axios({
    url: '/workorderbase/findPagePureUserWithWorkorder',
    method: 'post',
    data
  })
}

// 查询有未执行工单的客户.不带工单类型
export const findPagePureUserWithExecuteWorkorder = (data) => {
  return axios({
    url: '/workorderbase/findPagePureUserWithExecuteWorkorder',
    method: 'post',
    data
  })
}

// 根据照护计划号生成工单.
export const generateCare = (params) => {
  return axios({
    url: '/workorderbase/generateCare',
    method: 'get',
    params
  })
}

// 根据照护计划明细号生成按需的工单.
export const generateNessCare = (params) => {
  return axios({
    url: '/workorderbase/generateNessCare',
    method: 'get',
    params
  })
}

// 根据事故报告号生成工单.
export const generateAccident = (params) => {
  return axios({
    url: '/workorderbase/generateAccident',
    method: 'get',
    params
  })
}

// 根据紧急工单号生成工单.
export const generateUrgent = (params) => {
  return axios({
    url: '/workorderbase/generateUrgent',
    method: 'get',
    params
  })
}

// 保存并生成紧急工单.
export const saveGenerateUrgent = (data) => {
  return axios({
    url: '/workorderbase/saveGenerateUrgent',
    method: 'post',
    data
  })
}

// 暂停请假客户的工单.
export const leaveStop = (params) => {
  return axios({
    url: '/workorderbase/leaveStop',
    method: 'get',
    params
  })
}
