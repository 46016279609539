import axios from '../axios'

/*
 * 系统登录模块
 */

// 获取captcha
export const captcha = () => {
  return axios({
    url: 'captcha',
    method: 'get'
  })
}

// 登录
export const login = data => {
    return axios({
        url: 'login',
        method: 'post',
        data
    })
}

// 登出
export const logoutnew = () => {
    return axios({
        url: 'logoutnew',
        method: 'get'
    })
}

// 获取微信登录时的uuid.
export const wechatstate = () => {
  return axios({
    url: 'wechatstate',
    method: 'get'
  })
}

// 获取当前状态，是微信登陆还是普通登陆.
export const wechatloginstate = (params) => {
  return axios({
    url: 'wechatloginstate',
    method: 'get',
    params
  })
}
