var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.menu.children &&
    _vm.menu.children.length >= 1 &&
    _vm.menu.name !== "通用配置"
    ? _c(
        "el-submenu",
        { attrs: { index: "" + _vm.menu.id } },
        [
          _c("template", { slot: "title" }, [
            _c("i", { class: _vm.menu.icon }),
            _vm._v(" "),
            _c("span", { attrs: { slot: "title" }, slot: "title" }, [
              _vm._v(_vm._s(_vm.menu.name))
            ])
          ]),
          _vm._v(" "),
          _vm._l(_vm.menu.children, function(item) {
            return _c("MenuTree", { key: item.id, attrs: { menu: item } })
          })
        ],
        2
      )
    : _vm.menu.name !== "客户详情" &&
      _vm.menu.name !== "医嘱药单" &&
      _vm.menu.name !== "账单明细" &&
      _vm.menu.name !== "套餐详情" &&
      _vm.menu.name !== "照护计划服务内容配置" &&
      _vm.menu.name !== "照护计划详情" &&
      _vm.menu.name !== "照护计划详情按需" &&
      _vm.menu.name !== "考勤统计明细" &&
      _vm.menu.name !== "通用配置" &&
      _vm.menu.name !== "工单统计详情" &&
      _vm.menu.name !== "模版详情(菜单不显示)" &&
      _vm.menu.name !== "评估详情(菜单不显示)"
    ? _c(
        "el-menu-item",
        {
          attrs: { index: "" + _vm.menu.id },
          on: {
            click: function($event) {
              return _vm.handleRoute(_vm.menu)
            }
          }
        },
        [
          _c("i", { class: _vm.menu.icon }),
          _vm._v(" "),
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v(_vm._s(_vm.menu.name))
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2fd6d73a", { render: render, staticRenderFns: staticRenderFns })
  }
}