var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "main-container",
      class: _vm.$store.state.app.collapse
        ? "position-collapse-left"
        : "position-left",
      attrs: { id: "main-container" }
    },
    [
      _c(
        "div",
        { staticClass: "main-content" },
        [
          _c(
            "keep-alive",
            [_vm.$route.meta.keepAlive ? _c("router-view") : _vm._e()],
            1
          ),
          _vm._v(" "),
          !_vm.$route.meta.keepAlive ? _c("router-view") : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-becb8b48", { render: render, staticRenderFns: staticRenderFns })
  }
}