var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "page-container",
      staticStyle: { width: "99%", "margin-top": "15px" }
    },
    [
      _c(
        "el-carousel",
        {
          staticClass: "carousel",
          attrs: { interval: 3000, type: "card", height: "450px" }
        },
        [
          _c("el-carousel-item", { staticClass: "carousel-item-intro" }, [
            _c("h2", [_vm._v("海狮龙数据中心")]),
            _vm._v(" "),
            _c("ul", [
              _c("li", [_vm._v("完整")]),
              _vm._v(" "),
              _c("li", [_vm._v("准确")]),
              _vm._v(" "),
              _c("li", [_vm._v("迅速")])
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6edb6690", { render: render, staticRenderFns: staticRenderFns })
  }
}