import axios from '../axios'

/*
 * 照护区域排班模块
 */

// 保存
export const save = (data) => {
    return axios({
        url: '/careareaschedual/save',
        method: 'post',
        data
    })
}

// 删除
export const batchDelete = (data) => {
    return axios({
        url: '/careareaschedual/delete',
        method: 'post',
        data
    })
}

// 分页查询
export const findPage = (data) => {
  return axios({
    url: '/careareaschedual/findPage',
    method: 'post',
    data
  })
}

// 查询单条
export const findById = (params) => {
  return axios({
    url: '/careareaschedual/findById',
    method: 'get',
    params
  })
}


