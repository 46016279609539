var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "notice-panel" }, [
    _c("div", { staticClass: "header" }, [
      _vm._v("您有 " + _vm._s(_vm.data.length) + " 条通知")
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "notice-content" },
      _vm._l(_vm.data, function(item) {
        return _c("div", { key: item.key, staticClass: "notice-item" }, [
          _c("span", { staticClass: "notice-icon" }, [
            _c("li", { class: item.icon })
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "notice-cotent" }, [
            _vm._v("\n        " + _vm._s(item.content) + "\n      ")
          ])
        ])
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "notice-footer" }, [_vm._v("查看所有通知")])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-07b31383", { render: render, staticRenderFns: staticRenderFns })
  }
}