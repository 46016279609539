var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-popover",
        {
          ref: "popover",
          attrs: { placement: _vm.placement, trigger: "click" }
        },
        [
          _c("el-tree", {
            ref: "popupTree",
            attrs: {
              data: _vm.data,
              props: _vm.props,
              "node-key": "nodeKey",
              "default-expand-all": _vm.defaultExpandAll,
              "highlight-current": true,
              "expand-on-click-node": true
            },
            on: { "current-change": _vm.currentChangeHandle }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("el-input", {
        directives: [
          { name: "popover", rawName: "v-popover:popover", arg: "popover" }
        ],
        staticStyle: { cursor: "pointer" },
        attrs: { readonly: true, placeholder: _vm.placeholder },
        model: {
          value: _vm.prop,
          callback: function($$v) {
            _vm.prop = $$v
          },
          expression: "prop"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4397dc86", { render: render, staticRenderFns: staticRenderFns })
  }
}