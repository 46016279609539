import axios from '../axios'

/*
 * 财务管理之缴费记录模块
 */

// 保存
export const save = (data) => {
    return axios({
        url: '/financebillpay/save',
        method: 'post',
        data
    })
}
// 删除
export const batchDelete = (data) => {
    return axios({
        url: '/financebillpay/delete',
        method: 'post',
        data
    })
}
// 根据customerid查询详细信息
export const findByCustomerId = (params) => {
    return axios({
        url: '/financebillpay/findByCustomerId',
        method: 'get',
        params
    })
}

// 分页查询
export const findPage = (data) => {
  return axios({
    url: '/financebillpay/findPage',
    method: 'post',
    data
  })
}

