/*
 * 菜单管理模块
 */

// 获取导航菜单树
export function findNavTree() {
  const navTreeData = {
    "code": 200,
    "msg": null,
    "data": [
      {
        "id": 1,
        "createBy": null,
        "createTime": null,
        "lastUpdateBy": null,
        "lastUpdateTime": null,
        "parentId": 0,
        "name": "系统管理",
        "url": null,
        "perms": null,
        "type": 0,
        "icon": "el-icon-setting",
        "orderNum": 0,
        "delFlag": 0,
        "parentName": null,
        "level": 0,
        "children": [
          {
            "id": 2,
            "createBy": null,
            "createTime": null,
            "lastUpdateBy": null,
            "lastUpdateTime": null,
            "parentId": 1,
            "name": "用户管理",
            "url": "/sys/user",
            "perms": null,
            "type": 1,
            "icon": "el-icon-service",
            "orderNum": 1,
            "delFlag": 0,
            "parentName": "系统管理",
            "level": 1,
            "children": []
          },
          {
            "id": 7,
            "createBy": null,
            "createTime": null,
            "lastUpdateBy": null,
            "lastUpdateTime": null,
            "parentId": 1,
            "name": "机构管理",
            "url": "/sys/dept",
            "perms": null,
            "type": 1,
            "icon": "el-icon-news",
            "orderNum": 2,
            "delFlag": 0,
            "parentName": "系统管理",
            "level": 1,
            "children": []
          },
          {
            "id": 12,
            "createBy": null,
            "createTime": null,
            "lastUpdateBy": null,
            "lastUpdateTime": null,
            "parentId": 1,
            "name": "角色管理",
            "url": "/sys/role",
            "perms": null,
            "type": 1,
            "icon": "el-icon-view",
            "orderNum": 4,
            "delFlag": 0,
            "parentName": "系统管理",
            "level": 1,
            "children": []
          },
          {
            "id": 17,
            "createBy": null,
            "createTime": null,
            "lastUpdateBy": null,
            "lastUpdateTime": null,
            "parentId": 1,
            "name": "菜单管理",
            "url": "/sys/menu",
            "perms": null,
            "type": 1,
            "icon": "el-icon-menu",
            "orderNum": 5,
            "delFlag": 0,
            "parentName": "系统管理",
            "level": 1,
            "children": []
          },
          {
            "id": 22,
            "createBy": null,
            "createTime": null,
            "lastUpdateBy": null,
            "lastUpdateTime": null,
            "parentId": 1,
            "name": "字典管理",
            "url": "/sys/dict",
            "perms": null,
            "type": 1,
            "icon": "el-icon-edit-outline",
            "orderNum": 7,
            "delFlag": 0,
            "parentName": "系统管理",
            "level": 1,
            "children": []
          },
          {
            "id": 27,
            "createBy": null,
            "createTime": null,
            "lastUpdateBy": null,
            "lastUpdateTime": null,
            "parentId": 1,
            "name": "系统配置",
            "url": "/sys/config",
            "perms": null,
            "type": 1,
            "icon": "el-icon-edit-outline",
            "orderNum": 7,
            "delFlag": 0,
            "parentName": "系统管理",
            "level": 1,
            "children": []
          },
          {
            "id": 32,
            "createBy": null,
            "createTime": null,
            "lastUpdateBy": "admin",
            "lastUpdateTime": "2018-09-23T11:32:28.000+0000",
            "parentId": 1,
            "name": "登录日志",
            "url": "/sys/loginlog",
            "perms": null,
            "type": 1,
            "icon": "el-icon-info",
            "orderNum": 8,
            "delFlag": 0,
            "parentName": "系统管理",
            "level": 1,
            "children": []
          },
          {
            "id": 35,
            "createBy": null,
            "createTime": null,
            "lastUpdateBy": "admin",
            "lastUpdateTime": "2018-09-23T11:32:28.000+0000",
            "parentId": 1,
            "name": "操作日志",
            "url": "/sys/log",
            "perms": null,
            "type": 1,
            "icon": "el-icon-info",
            "orderNum": 8,
            "delFlag": 0,
            "parentName": "系统管理",
            "level": 1,
            "children": []
          }
        ]
      },
      {
        "id": 12,
        "createBy": null,
        "createTime": null,
        "lastUpdateBy": "admin",
        "lastUpdateTime": "2018-11-15T06:39:43.000+0000",
        "parentId": 0,
        "name": "长者管理",
        "url": null,
        "perms": null,
        "type": 0,
        "icon": "el-icon-picture-outline",
        "orderNum": 6,
        "delFlag": 0,
        "parentName": null,
        "level": 0,
        "children": [
          {
            "id": 13,
            "createBy": null,
            "createTime": null,
            "lastUpdateBy": null,
            "lastUpdateTime": null,
            "parentId": 12,
            "name": "长者详情",
            "url": "/senior/detail",
            "perms": null,
            "type": 1,
            "icon": "el-icon-edit",
            "orderNum": 1,
            "delFlag": 0,
            "parentName": "长者管理",
            "level": 1,
            "children": []
          }
        ]
      },
      {
        "id": 22,
        "createBy": null,
        "createTime": null,
        "lastUpdateBy": "admin",
        "lastUpdateTime": "2018-11-15T06:39:43.000+0000",
        "parentId": 0,
        "name": "设备管理",
        "url": null,
        "perms": null,
        "type": 0,
        "icon": "el-icon-picture-outline",
        "orderNum": 6,
        "delFlag": 0,
        "parentName": null,
        "level": 0,
        "children": [
          {
            "id": 23,
            "createBy": null,
            "createTime": null,
            "lastUpdateBy": null,
            "lastUpdateTime": null,
            "parentId": 22,
            "name": "设备清单",
            "url": "/device/devicelist",
            "perms": null,
            "type": 1,
            "icon": "el-icon-edit",
            "orderNum": 1,
            "delFlag": 0,
            "parentName": "设备管理",
            "level": 1,
            "children": []
          }
        ]
      },
      {
        "id": 32,
        "createBy": null,
        "createTime": null,
        "lastUpdateBy": "admin",
        "lastUpdateTime": "2018-11-15T06:39:43.000+0000",
        "parentId": 0,
        "name": "数据统计",
        "url": null,
        "perms": null,
        "type": 0,
        "icon": "el-icon-picture-outline",
        "orderNum": 6,
        "delFlag": 0,
        "parentName": null,
        "level": 0,
        "children": [
          {
            "id": 33,
            "createBy": null,
            "createTime": null,
            "lastUpdateBy": null,
            "lastUpdateTime": null,
            "parentId": 32,
            "name": "数据概况",
            "url": "/statics/datasummary",
            "perms": null,
            "type": 1,
            "icon": "el-icon-edit",
            "orderNum": 1,
            "delFlag": 0,
            "parentName": "数据统计",
            "level": 1,
            "children": []
          },
          {
            "id": 34,
            "createBy": null,
            "createTime": null,
            "lastUpdateBy": null,
            "lastUpdateTime": null,
            "parentId": 32,
            "name": "呼叫服务统计",
            "url": "/statics/callsummary",
            "perms": null,
            "type": 1,
            "icon": "el-icon-edit",
            "orderNum": 1,
            "delFlag": 0,
            "parentName": "数据统计",
            "level": 1,
            "children": []
          }
        ]
      }
    ]
  }
  return {
    url: 'menu/findNavTree',
    type: 'get',
    data: navTreeData
  }
}

// 获取菜单树
export function findMenuTree() {
  const menuTreeData = {
    "code": 200,
    "msg": null,
    "data": [
      {
        "id": 1,
        "createBy": null,
        "createTime": null,
        "lastUpdateBy": null,
        "lastUpdateTime": null,
        "parentId": 0,
        "name": "系统管理",
        "url": null,
        "perms": null,
        "type": 0,
        "icon": "el-icon-setting",
        "orderNum": 0,
        "delFlag": 0,
        "parentName": null,
        "level": 0,
        "children": [
          {
            "id": 2,
            "createBy": null,
            "createTime": null,
            "lastUpdateBy": null,
            "lastUpdateTime": null,
            "parentId": 1,
            "name": "用户管理",
            "url": "/sys/user",
            "perms": null,
            "type": 1,
            "icon": "el-icon-service",
            "orderNum": 1,
            "delFlag": 0,
            "parentName": "系统管理",
            "level": 1,
            "children": [
              {
                "id": 3,
                "createBy": null,
                "createTime": null,
                "lastUpdateBy": null,
                "lastUpdateTime": null,
                "parentId": 2,
                "name": "查看",
                "url": null,
                "perms": "sys:user:view",
                "type": 2,
                "icon": null,
                "orderNum": 0,
                "delFlag": 0,
                "parentName": "用户管理",
                "level": 2,
                "children": []
              },
              {
                "id": 4,
                "createBy": null,
                "createTime": null,
                "lastUpdateBy": null,
                "lastUpdateTime": null,
                "parentId": 2,
                "name": "新增",
                "url": null,
                "perms": "sys:user:add",
                "type": 2,
                "icon": null,
                "orderNum": 0,
                "delFlag": 0,
                "parentName": "用户管理",
                "level": 2,
                "children": []
              },
              {
                "id": 5,
                "createBy": null,
                "createTime": null,
                "lastUpdateBy": null,
                "lastUpdateTime": null,
                "parentId": 2,
                "name": "修改",
                "url": null,
                "perms": "sys:user:edit",
                "type": 2,
                "icon": null,
                "orderNum": 0,
                "delFlag": 0,
                "parentName": "用户管理",
                "level": 2,
                "children": []
              },
              {
                "id": 6,
                "createBy": null,
                "createTime": null,
                "lastUpdateBy": null,
                "lastUpdateTime": null,
                "parentId": 2,
                "name": "删除",
                "url": null,
                "perms": "sys:user:delete",
                "type": 2,
                "icon": null,
                "orderNum": 0,
                "delFlag": 0,
                "parentName": "用户管理",
                "level": 2,
                "children": []
              }
            ]
          },
          {
            "id": 7,
            "createBy": null,
            "createTime": null,
            "lastUpdateBy": null,
            "lastUpdateTime": null,
            "parentId": 1,
            "name": "机构管理",
            "url": "/sys/dept",
            "perms": null,
            "type": 1,
            "icon": "el-icon-news",
            "orderNum": 2,
            "delFlag": 0,
            "parentName": "系统管理",
            "level": 1,
            "children": [
              {
                "id": 8,
                "createBy": null,
                "createTime": null,
                "lastUpdateBy": null,
                "lastUpdateTime": null,
                "parentId": 7,
                "name": "查看",
                "url": null,
                "perms": "sys:dept:view",
                "type": 2,
                "icon": null,
                "orderNum": 0,
                "delFlag": 0,
                "parentName": "机构管理",
                "level": 2,
                "children": []
              },
              {
                "id": 9,
                "createBy": null,
                "createTime": null,
                "lastUpdateBy": null,
                "lastUpdateTime": null,
                "parentId": 7,
                "name": "新增",
                "url": null,
                "perms": "sys:dept:add",
                "type": 2,
                "icon": null,
                "orderNum": 0,
                "delFlag": 0,
                "parentName": "机构管理",
                "level": 2,
                "children": []
              },
              {
                "id": 10,
                "createBy": null,
                "createTime": null,
                "lastUpdateBy": null,
                "lastUpdateTime": null,
                "parentId": 7,
                "name": "修改",
                "url": null,
                "perms": "sys:dept:edit",
                "type": 2,
                "icon": null,
                "orderNum": 0,
                "delFlag": 0,
                "parentName": "机构管理",
                "level": 2,
                "children": []
              },
              {
                "id": 11,
                "createBy": null,
                "createTime": null,
                "lastUpdateBy": null,
                "lastUpdateTime": null,
                "parentId": 7,
                "name": "删除",
                "url": null,
                "perms": "sys:dept:delete",
                "type": 2,
                "icon": null,
                "orderNum": 0,
                "delFlag": 0,
                "parentName": "机构管理",
                "level": 2,
                "children": []
              }
            ]
          },
          {
            "id": 12,
            "createBy": null,
            "createTime": null,
            "lastUpdateBy": null,
            "lastUpdateTime": null,
            "parentId": 1,
            "name": "角色管理",
            "url": "/sys/role",
            "perms": null,
            "type": 1,
            "icon": "el-icon-view",
            "orderNum": 4,
            "delFlag": 0,
            "parentName": "系统管理",
            "level": 1,
            "children": [
              {
                "id": 13,
                "createBy": null,
                "createTime": null,
                "lastUpdateBy": null,
                "lastUpdateTime": null,
                "parentId": 12,
                "name": "查看",
                "url": null,
                "perms": "sys:role:view",
                "type": 2,
                "icon": null,
                "orderNum": 0,
                "delFlag": 0,
                "parentName": "角色管理",
                "level": 2,
                "children": []
              },
              {
                "id": 14,
                "createBy": null,
                "createTime": null,
                "lastUpdateBy": null,
                "lastUpdateTime": null,
                "parentId": 12,
                "name": "新增",
                "url": null,
                "perms": "sys:role:add",
                "type": 2,
                "icon": null,
                "orderNum": 0,
                "delFlag": 0,
                "parentName": "角色管理",
                "level": 2,
                "children": []
              },
              {
                "id": 15,
                "createBy": null,
                "createTime": null,
                "lastUpdateBy": null,
                "lastUpdateTime": null,
                "parentId": 12,
                "name": "修改",
                "url": null,
                "perms": "sys:role:edit",
                "type": 2,
                "icon": null,
                "orderNum": 0,
                "delFlag": 0,
                "parentName": "角色管理",
                "level": 2,
                "children": []
              },
              {
                "id": 16,
                "createBy": null,
                "createTime": null,
                "lastUpdateBy": null,
                "lastUpdateTime": null,
                "parentId": 12,
                "name": "删除",
                "url": null,
                "perms": "sys:role:delete",
                "type": 2,
                "icon": null,
                "orderNum": 0,
                "delFlag": 0,
                "parentName": "角色管理",
                "level": 2,
                "children": []
              }
            ]
          },
          {
            "id": 17,
            "createBy": null,
            "createTime": null,
            "lastUpdateBy": null,
            "lastUpdateTime": null,
            "parentId": 1,
            "name": "菜单管理",
            "url": "/sys/menu",
            "perms": null,
            "type": 1,
            "icon": "el-icon-menu",
            "orderNum": 5,
            "delFlag": 0,
            "parentName": "系统管理",
            "level": 1,
            "children": [
              {
                "id": 18,
                "createBy": null,
                "createTime": null,
                "lastUpdateBy": null,
                "lastUpdateTime": null,
                "parentId": 17,
                "name": "查看",
                "url": null,
                "perms": "sys:menu:view",
                "type": 2,
                "icon": null,
                "orderNum": 0,
                "delFlag": 0,
                "parentName": "菜单管理",
                "level": 2,
                "children": []
              },
              {
                "id": 19,
                "createBy": null,
                "createTime": null,
                "lastUpdateBy": null,
                "lastUpdateTime": null,
                "parentId": 17,
                "name": "新增",
                "url": null,
                "perms": "sys:menu:add",
                "type": 2,
                "icon": null,
                "orderNum": 0,
                "delFlag": 0,
                "parentName": "菜单管理",
                "level": 2,
                "children": []
              },
              {
                "id": 20,
                "createBy": null,
                "createTime": null,
                "lastUpdateBy": null,
                "lastUpdateTime": null,
                "parentId": 17,
                "name": "修改",
                "url": null,
                "perms": "sys:menu:edit",
                "type": 2,
                "icon": null,
                "orderNum": 0,
                "delFlag": 0,
                "parentName": "菜单管理",
                "level": 2,
                "children": []
              },
              {
                "id": 21,
                "createBy": null,
                "createTime": null,
                "lastUpdateBy": null,
                "lastUpdateTime": null,
                "parentId": 17,
                "name": "删除",
                "url": null,
                "perms": "sys:menu:delete",
                "type": 2,
                "icon": null,
                "orderNum": 0,
                "delFlag": 0,
                "parentName": "菜单管理",
                "level": 2,
                "children": []
              }
            ]
          },
          {
            "id": 22,
            "createBy": null,
            "createTime": null,
            "lastUpdateBy": null,
            "lastUpdateTime": null,
            "parentId": 1,
            "name": "字典管理",
            "url": "/sys/dict",
            "perms": null,
            "type": 1,
            "icon": "el-icon-edit-outline",
            "orderNum": 7,
            "delFlag": 0,
            "parentName": "系统管理",
            "level": 1,
            "children": [
              {
                "id": 23,
                "createBy": null,
                "createTime": null,
                "lastUpdateBy": null,
                "lastUpdateTime": null,
                "parentId": 22,
                "name": "查看",
                "url": null,
                "perms": "sys:dict:view",
                "type": 2,
                "icon": null,
                "orderNum": 0,
                "delFlag": 0,
                "parentName": "字典管理",
                "level": 2,
                "children": []
              },
              {
                "id": 24,
                "createBy": null,
                "createTime": null,
                "lastUpdateBy": null,
                "lastUpdateTime": null,
                "parentId": 22,
                "name": "新增",
                "url": null,
                "perms": "sys:dict:add",
                "type": 2,
                "icon": null,
                "orderNum": 0,
                "delFlag": 0,
                "parentName": "字典管理",
                "level": 2,
                "children": []
              },
              {
                "id": 25,
                "createBy": null,
                "createTime": null,
                "lastUpdateBy": null,
                "lastUpdateTime": null,
                "parentId": 22,
                "name": "修改",
                "url": null,
                "perms": "sys:dict:edit",
                "type": 2,
                "icon": null,
                "orderNum": 0,
                "delFlag": 0,
                "parentName": "字典管理",
                "level": 2,
                "children": []
              },
              {
                "id": 26,
                "createBy": null,
                "createTime": null,
                "lastUpdateBy": null,
                "lastUpdateTime": null,
                "parentId": 22,
                "name": "删除",
                "url": null,
                "perms": "sys:dict:delete",
                "type": 2,
                "icon": null,
                "orderNum": 0,
                "delFlag": 0,
                "parentName": "字典管理",
                "level": 2,
                "children": []
              }
            ]
          },
          {
            "id": 27,
            "createBy": null,
            "createTime": null,
            "lastUpdateBy": null,
            "lastUpdateTime": null,
            "parentId": 1,
            "name": "系统配置",
            "url": "/sys/config",
            "perms": null,
            "type": 1,
            "icon": "el-icon-edit-outline",
            "orderNum": 7,
            "delFlag": 0,
            "parentName": "系统管理",
            "level": 1,
            "children": [
              {
                "id": 28,
                "createBy": null,
                "createTime": null,
                "lastUpdateBy": null,
                "lastUpdateTime": null,
                "parentId": 27,
                "name": "查看",
                "url": null,
                "perms": "sys:config:view",
                "type": 2,
                "icon": null,
                "orderNum": 0,
                "delFlag": 0,
                "parentName": "系统配置",
                "level": 2,
                "children": []
              },
              {
                "id": 29,
                "createBy": null,
                "createTime": null,
                "lastUpdateBy": null,
                "lastUpdateTime": null,
                "parentId": 27,
                "name": "新增",
                "url": null,
                "perms": "sys:config:add",
                "type": 2,
                "icon": null,
                "orderNum": 0,
                "delFlag": 0,
                "parentName": "系统配置",
                "level": 2,
                "children": []
              },
              {
                "id": 30,
                "createBy": null,
                "createTime": null,
                "lastUpdateBy": null,
                "lastUpdateTime": null,
                "parentId": 27,
                "name": "修改",
                "url": null,
                "perms": "sys:config:edit",
                "type": 2,
                "icon": null,
                "orderNum": 0,
                "delFlag": 0,
                "parentName": "系统配置",
                "level": 2,
                "children": []
              },
              {
                "id": 31,
                "createBy": null,
                "createTime": null,
                "lastUpdateBy": null,
                "lastUpdateTime": null,
                "parentId": 27,
                "name": "删除",
                "url": null,
                "perms": "sys:config:delete",
                "type": 2,
                "icon": null,
                "orderNum": 0,
                "delFlag": 0,
                "parentName": "系统配置",
                "level": 2,
                "children": []
              }
            ]
          },
          {
            "id": 32,
            "createBy": null,
            "createTime": null,
            "lastUpdateBy": "admin",
            "lastUpdateTime": "2018-09-23T11:32:28.000+0000",
            "parentId": 1,
            "name": "登录日志",
            "url": "/sys/loginlog",
            "perms": null,
            "type": 1,
            "icon": "el-icon-info",
            "orderNum": 8,
            "delFlag": 0,
            "parentName": "系统管理",
            "level": 1,
            "children": [
              {
                "id": 33,
                "createBy": null,
                "createTime": null,
                "lastUpdateBy": null,
                "lastUpdateTime": null,
                "parentId": 32,
                "name": "查看",
                "url": null,
                "perms": "sys:loginlog:view",
                "type": 2,
                "icon": null,
                "orderNum": 0,
                "delFlag": 0,
                "parentName": "登录日志",
                "level": 2,
                "children": []
              },
              {
                "id": 34,
                "createBy": null,
                "createTime": null,
                "lastUpdateBy": null,
                "lastUpdateTime": null,
                "parentId": 32,
                "name": "删除",
                "url": null,
                "perms": "sys:loginlog:delete",
                "type": 2,
                "icon": null,
                "orderNum": 0,
                "delFlag": 0,
                "parentName": "登录日志",
                "level": 2,
                "children": []
              }
            ]
          },
          {
            "id": 35,
            "createBy": null,
            "createTime": null,
            "lastUpdateBy": "admin",
            "lastUpdateTime": "2018-09-23T11:32:28.000+0000",
            "parentId": 1,
            "name": "操作日志",
            "url": "/sys/log",
            "perms": null,
            "type": 1,
            "icon": "el-icon-info",
            "orderNum": 8,
            "delFlag": 0,
            "parentName": "系统管理",
            "level": 1,
            "children": [
              {
                "id": 36,
                "createBy": null,
                "createTime": null,
                "lastUpdateBy": null,
                "lastUpdateTime": null,
                "parentId": 35,
                "name": "查看",
                "url": null,
                "perms": "sys:log:view",
                "type": 2,
                "icon": null,
                "orderNum": 0,
                "delFlag": 0,
                "parentName": "操作日志",
                "level": 2,
                "children": []
              },
              {
                "id": 37,
                "createBy": null,
                "createTime": null,
                "lastUpdateBy": null,
                "lastUpdateTime": null,
                "parentId": 35,
                "name": "删除",
                "url": null,
                "perms": "sys:log:delete",
                "type": 2,
                "icon": null,
                "orderNum": 0,
                "delFlag": 0,
                "parentName": "操作日志",
                "level": 2,
                "children": []
              }
            ]
          }
        ]
      },
      {
        "id": 12,
        "createBy": null,
        "createTime": null,
        "lastUpdateBy": "admin",
        "lastUpdateTime": "2018-11-15T06:39:43.000+0000",
        "parentId": 0,
        "name": "长者管理",
        "url": null,
        "perms": null,
        "type": 0,
        "icon": "el-icon-picture-outline",
        "orderNum": 6,
        "delFlag": 0,
        "parentName": null,
        "level": 0,
        "children": [
          {
            "id": 13,
            "createBy": null,
            "createTime": null,
            "lastUpdateBy": null,
            "lastUpdateTime": null,
            "parentId": 12,
            "name": "长者详情",
            "url": "/senior/detail",
            "perms": null,
            "type": 1,
            "icon": "el-icon-edit",
            "orderNum": 1,
            "delFlag": 0,
            "parentName": "长者管理",
            "level": 1,
            "children": [
              {
                "id": 54,
                "createBy": null,
                "createTime": null,
                "lastUpdateBy": null,
                "lastUpdateTime": null,
                "parentId": 12,
                "name": "查看",
                "url": null,
                "perms": "sys:dict:view",
                "type": 2,
                "icon": null,
                "orderNum": 0,
                "delFlag": 0,
                "parentName": "长者详情",
                "level": 2,
                "children": []
              }
            ]
          }
        ]
      },
      {
        "id": 22,
        "createBy": null,
        "createTime": null,
        "lastUpdateBy": "admin",
        "lastUpdateTime": "2018-11-15T06:39:43.000+0000",
        "parentId": 0,
        "name": "设备管理",
        "url": null,
        "perms": null,
        "type": 0,
        "icon": "el-icon-picture-outline",
        "orderNum": 6,
        "delFlag": 0,
        "parentName": null,
        "level": 0,
        "children": [
          {
            "id": 23,
            "createBy": null,
            "createTime": null,
            "lastUpdateBy": null,
            "lastUpdateTime": null,
            "parentId": 22,
            "name": "设备列表",
            "url": "/device/devicelist",
            "perms": null,
            "type": 1,
            "icon": "el-icon-edit",
            "orderNum": 1,
            "delFlag": 0,
            "parentName": "设备列表",
            "level": 1,
            "children": [
              {
                "id": 64,
                "createBy": null,
                "createTime": null,
                "lastUpdateBy": null,
                "lastUpdateTime": null,
                "parentId": 22,
                "name": "查看",
                "url": null,
                "perms": "sys:dict:view",
                "type": 2,
                "icon": null,
                "orderNum": 0,
                "delFlag": 0,
                "parentName": "设备列表",
                "level": 2,
                "children": []
              }
            ]
          }
        ]
      },
      {
        "id": 32,
        "createBy": null,
        "createTime": null,
        "lastUpdateBy": "admin",
        "lastUpdateTime": "2018-11-15T06:39:43.000+0000",
        "parentId": 0,
        "name": "数据统计",
        "url": null,
        "perms": null,
        "type": 0,
        "icon": "el-icon-picture-outline",
        "orderNum": 6,
        "delFlag": 0,
        "parentName": null,
        "level": 0,
        "children": [
          {
            "id": 33,
            "createBy": null,
            "createTime": null,
            "lastUpdateBy": null,
            "lastUpdateTime": null,
            "parentId": 32,
            "name": "数据统计",
            "url": "/statics/datasummary",
            "perms": null,
            "type": 1,
            "icon": "el-icon-edit",
            "orderNum": 1,
            "delFlag": 0,
            "parentName": "数据统计",
            "level": 1,
            "children": [
              {
                "id": 34,
                "createBy": null,
                "createTime": null,
                "lastUpdateBy": null,
                "lastUpdateTime": null,
                "parentId": 32,
                "name": "查看",
                "url": null,
                "perms": "sys:dict:view",
                "type": 2,
                "icon": null,
                "orderNum": 0,
                "delFlag": 0,
                "parentName": "数据统计",
                "level": 2,
                "children": []
              }
            ]
          },
          {
            "id": 43,
            "createBy": null,
            "createTime": null,
            "lastUpdateBy": null,
            "lastUpdateTime": null,
            "parentId": 32,
            "name": "呼叫统计",
            "url": "/statics/callsummary",
            "perms": null,
            "type": 1,
            "icon": "el-icon-edit",
            "orderNum": 1,
            "delFlag": 0,
            "parentName": "数据统计",
            "level": 1,
            "children": [
              {
                "id": 44,
                "createBy": null,
                "createTime": null,
                "lastUpdateBy": null,
                "lastUpdateTime": null,
                "parentId": 32,
                "name": "查看",
                "url": null,
                "perms": "sys:dict:view",
                "type": 2,
                "icon": null,
                "orderNum": 0,
                "delFlag": 0,
                "parentName": "数据统计",
                "level": 2,
                "children": []
              }
            ]
          }
        ]
      }
    ]
  }
  return {
    url: 'menu/findMenuTree',
    type: 'get',
    data: menuTreeData
  }
}

export function setParentName(data) {
  if(data == null) {
    return
  }
  let len = data.length
  for(let i=0; i<len; i++) {
    let menu = data[i]
    menu.parentName = 'menu' + menu.parentId
    if(menu.children != null) {
      setParentName(menu.children)
    }
  }
}

