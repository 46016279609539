
import moment from 'moment-timezone';
/**
 * 时间日期相关操作
 */


/**
 * 按照UTC8的东八区处理时间
 * @param strDateTime
 * @returns {string}
 */
export function dateFormatUTC8 (strDateTime) {
  let localDate = Date.parse(strDateTime)
  let momentDate = moment.tz(localDate, moment.tz.guess())
  return momentDate.format("YYYY-MM-DDTHH:mm:ss")
}


// 将传入的YYYY-MM-DDTHH:mm:ss.000+0000时间转换为格林威治时间YYYY-MM-DDTHH:mm:ss
export function transferTime(time){
  // 目标时间
  const defaultTime = time;
  // 本地指定时间时间戳
  const defaultNumberTime = new Date(defaultTime).getTime();
  // 获取本地时间和格林威治时间相差的毫秒数
  let localOffset = new Date().getTimezoneOffset() * 60000;
  localOffset = localOffset > 0 ? -localOffset : localOffset;
  // 得到完整的格林威治时间
  const utc = defaultNumberTime + localOffset;
  const localTime = utc
  return moment(localTime).format("YYYY-MM-DDTHH:mm:ss")
}




/**
 * 将时间格式化成字符串，避免UTC的时区.
 * @param strDateTime 字符串的时间格式处理
 * @return 字符串的完整时间格式: 格式化之后的时间
 */
export function formatStrTime (strDateTime) {

  let localYears = 0;
  let localMonths = 0;
  let localDate = 0;
  let localHours = 0;
  let localMinutes = 0;
  let localSeconds = 0;

  //传入的就是时间格式
  //
  if (strDateTime.toString().length>28)
  {
    localYears = strDateTime.getFullYear().toString()
    localMonths = fillZero((strDateTime.getMonth()+1).toString())
    localDate = fillZero(strDateTime.getDate().toString())
    localHours = fillZero(strDateTime.getHours().toString())
    localMinutes = fillZero(strDateTime.getMinutes().toString())
    localSeconds = fillZero(strDateTime.getSeconds().toString())
  }
  else if (strDateTime.toString().length === 8)
  {
    // 13:38:36 格式
    // 仅仅传入时间.
    let nowDate = new Date()
    localYears = nowDate.getFullYear().toString()
    localMonths = fillZero((nowDate.getMonth()+1).toString())
    localDate = fillZero(nowDate.getDate().toString())

    localHours = fillZero(strDateTime.substring(0,2))
    localMinutes = fillZero(strDateTime.substring(3,5))
    localSeconds = fillZero(strDateTime.substring(6,8))
  }
  else
    // 1970-00-00T11:54:16.000+0000 格式
    //传入的是字符串
  {
    localYears = strDateTime.substring(0,4)
    localMonths = fillZero(strDateTime.substring(5,7))
    localDate = fillZero(strDateTime.substring(8,10))
    localHours = fillZero(strDateTime.substring(11,13))
    localMinutes = fillZero(strDateTime.substring(14,16))
    localSeconds = fillZero(strDateTime.substring(17,19))
  }

  return localYears + '-' + localMonths + '-' + localDate + 'T' + localHours + ':' + localMinutes + ':' + localSeconds
}

// fill 0
function fillZero(strInput) {
  if (strInput.length === 1) {
    strInput = "0" + strInput
  }
  return strInput
}


/**
 * 时间格式化
 * 将 2018-09-23T11:54:16.000+0000 格式化成 2018/09/23 11:54:16
 * @param datetime 国际化日期格式
 */
export function format (datetime) {
  return formatWithSeperator1(datetime, "/", ":");
}

/**
 * 时间格式化,只要日期.
 * 将 2018-09-23T11:54:16.000+0000 格式化成 2018/09/23
 * @param datetime 国际化日期格式
 */
export function formatOnlyDate (datetime) {
  return formatWithSeperatorOnlyDate(datetime, "/");
}

/**
 * 时间格式化
 * 将 2018-09-23T11:54:16.000+0000 格式化成类似 2018/09/23 11:54:16
 * 可以指定日期和时间分隔符
 * @param datetime 国际化日期格式
 */
export function formatWithSeperator1 (datetime, dateSeprator, timeSeprator) {
  if (datetime != null) {
    const dateMat = new Date(datetime);
    const year = dateMat.getFullYear();
    const month = dateMat.getMonth() + 1;
    const date = dateMat.getDate();
    const hh = dateMat.getHours();
    const mm = dateMat.getMinutes();
    const ss = dateMat.getSeconds();
    const timeFormat = year + dateSeprator + formatNumber(month) + dateSeprator + formatNumber(date) + " " +
      formatNumber(hh) + timeSeprator + formatNumber(mm);
    return timeFormat;
  }
}

/**
 * 时间格式化 只要日期.
 * 将 2018-09-23T11:54:16.000+0000 格式化成类似 2018/09/23
 * 可以指定日期分隔符
 * @param datetime 国际化日期格式
 */
export function formatWithSeperatorOnlyDate (datetime, dateSeprator) {
  if (datetime != null) {
    const dateMat = new Date(datetime);
    const year = dateMat.getFullYear();
    const month = dateMat.getMonth() + 1;
    const date = dateMat.getDate();
    const timeFormat = year + dateSeprator + formatNumber(month) + dateSeprator + formatNumber(date);
    return timeFormat;
  }
}


/**
 * 时间格式化
 * 将 2018-09-23T11:54:16.000+0000 格式化成 11:54:16
 * @param datetime 国际化日期格式
 */
export function formatTime (datetime) {
  if (datetime != null) {
    const dateMat = new Date(datetime);
    const hh = dateMat.getHours();
    const mm = dateMat.getMinutes();
    const timeFormat = formatNumber(hh) + ":" + formatNumber(mm);
    return timeFormat;
  }
}

/**
 * 月份格式化
 * 将 2018-09-23T11:54:16.000+0000 格式化成 2018/09
 * @param datetime 国际化日期格式
 */
export function formatMonth (datetime) {
  return formatMonthWithSeperator(datetime, "/", ":");
}

/**
 * 天格式化
 * 将 2018-09-23T11:54:16.000+0000 格式化成 2018/09/23
 * @param datetime 国际化日期格式
 */
export function formatDay (datetime) {
  return formatDayWithSeperator(datetime, "/", ":");
}

/**
 * 时间格式化
 * 将 2018-09-23T11:54:16.000+0000 格式化成类似 2018/09/23 11:54:16
 * 可以指定日期和时间分隔符
 * @param datetime 国际化日期格式
 */
export function formatWithSeperator (datetime, dateSeprator, timeSeprator) {
  if (datetime != null) {
    const dateMat = new Date(datetime);
    const year = dateMat.getFullYear();
    const month = dateMat.getMonth() + 1;
    const date = dateMat.getDate();
    const hh = dateMat.getHours();
    const mm = dateMat.getMinutes();
    const ss = dateMat.getSeconds();
    const timeFormat = year + dateSeprator + formatNumber(month) + dateSeprator + formatNumber(date) + " " +
      formatNumber(hh) + timeSeprator + formatNumber(mm) + timeSeprator + formatNumber(ss);
    return timeFormat;
  }
}

/**
 * 月份格式化
 * 将 2018-09-23T11:54:16.000+0000 格式化成类似 2018/09
 * 可以指定日期和时间分隔符
 * @param datetime 国际化日期格式
 */
export function formatMonthWithSeperator (datetime, dateSeprator, timeSeprator) {
  if (datetime != null) {
    const dateMat = new Date(datetime);
    const year = dateMat.getFullYear();
    const month = dateMat.getMonth() + 1;
    const monthFormat = year + '年' + month + '月';
    return monthFormat;
  }
}

/**
 * 天格式化
 * 将 2018-09-23T11:54:16.000+0000 格式化成类似 2018年09月23日
 * 可以指定日期和时间分隔符
 * @param datetime 国际化日期格式
 */
export function formatDayWithSeperator (datetime, dateSeprator, timeSeprator) {
  if (datetime != null) {
    const dateMat = new Date(datetime);
    const year = dateMat.getFullYear();
    const month = dateMat.getMonth() + 1;
    const date = dateMat.getDate();
    const dayFormat = year + '年' + month + '月' + date + '日';
    return dayFormat;
  }
}

/**
 * 天格式化
 * 将 2018-09-23T11:54:16.000+0000 格式化成类似 2018分隔符09分隔符23
 * 可以指定日期和时间分隔符
 * @param datetime 国际化日期格式
 * @param dateSeprator seprator
 */
export function getDayWithSeperator (datetime, dateSeprator) {
  if (datetime != null) {
    const dateMat = new Date(datetime);
    const year = dateMat.getFullYear();
    const month = dateMat.getMonth() + 1;
    const date = dateMat.getDate();
    return [year, month, date].map(formatNumber).join(dateSeprator);
  }
}

/**
 * 时间格式化
 * 将 2018-09-23T11:54:16.000+0000 格式化成类似 11分隔符54分隔符16
 * 可以指定日期和时间分隔符
 * @param datetime 国际化日期格式
 * @param timeSeprator seprator
 */
export function getTimeWithSeperator (datetime, timeSeprator) {
  if (datetime != null) {
    const dateMat = new Date(datetime);
    const hour = dateMat.getHours();
    const minute = dateMat.getMinutes();
    const second = dateMat.getSeconds();
    return [hour, minute, second].map(formatNumber).join(timeSeprator)
  }
}

const formatNumber = n => {
  n = n.toString()
  return n[1] ? n : '0' + n
}
