var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "theme-selector" },
    [
      _c(
        "el-popover",
        {
          attrs: {
            placement: "bottom",
            title: "主题",
            width: "200",
            trigger: "hover"
          }
        },
        [
          _c(
            "el-button",
            {
              attrs: { slot: "reference", type: "text" },
              on: {
                click: function($event) {
                  _vm.dialogVisible = true
                }
              },
              slot: "reference"
            },
            [
              _c("i", {
                staticClass: "el-icon-school",
                staticStyle: { color: "#00b6ed" }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-table",
                { attrs: { data: _vm.themeList } },
                [
                  _c("el-table-column", {
                    attrs: { prop: "title", align: "center" }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "address", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm.theme === scope.row.name
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      size: "mini",
                                      type: "success",
                                      icon: "el-icon-check",
                                      round: ""
                                    }
                                  },
                                  [_vm._v("已激活\n            ")]
                                )
                              : _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", round: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleSelectTheme(
                                          scope.row.name
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("使用")]
                                )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3da8d9de", { render: render, staticRenderFns: staticRenderFns })
  }
}