import axios from '../axios'

// 获取模版清单
export const findPage = (data) => {
  return axios({
    url: '/assesstemplate/findPage',
    data,
    method: 'post'
  })
}

//获取模版的表
export const findFormByTemplateId = (data) => {
  return axios({
    url: '/assessform/findPage',
    data,
    method: 'post'
  })
}

//获取模版中表对应的题目
export const assessquestion = (params) => {
  return axios({
    url: '/assessform/findFormTreeById',
    params,
    method: 'get'
  })
}

//新增评估工单
export const initialtemplatetree = (params, data) => {
  return axios({
    url: '/customerassesstemplate/initialtemplatetree',
    params,
    data,
    method: 'post'
  })
}

// 取消评估工单
export const cancel = (data) => {
  return axios({
    url: '/customerassesstemplate/cancel',
    method: 'post',
    data
  })
}

//获取评估记录
export const customerassesstemplate = (data) => {
  return axios({
    url: '/customerassesstemplate/findPage',
    data,
    method: 'post'
  })
}

//获取评估记录的表单
export const customerassessform = (data) => {
  return axios({
    url: '/customerassessform/findPage',
    data,
    method: 'post'
  })
}

//获取评估记录表单的详情
export const customerassessquestion = (params) => {
  return axios({
    url: '/customerassessform/findFormTreeById',
    params,
    method: 'get'
  })
}

//提交评估记录表单详情
export const saveformtreeanswer = (data) => {
  return axios({
    url: '/customerassessform/saveformtreeanswer',
    data,
    method: 'post'
  })
}
//获取评估报告
export const findCareLevelReportCombine = (params) => {
  return axios({
    url: '/customerassesstemplate/findTemplateReportCombine',
    params,
    method: 'get'
  })
}

//生成评估报告
export const generateTemplateTreeResult = (data) => {
  return axios({
    url: '/customerassesstemplate/generateTemplateTreeResult',
    data,
    method: 'post'
  })
}

//保存评估报告
export const saveTemplateTreeResult = (data) => {
  return axios({
    url: '/customerassesstemplate/saveTemplateTreeResult',
    data,
    method: 'post'
  })
}
