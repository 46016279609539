import Vue from 'vue'

import api from './http'
import i18n from './i18n'
import store from './store'
import global from '@/utils/global'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'font-awesome/css/font-awesome.min.css'
// "./assets/public/scss/themefy.scss";
// import './assets/public/scss/themefy.css'
import router from './router'

//引入 echarts
// echarts 5.x
import * as echarts from 'echarts'
// echarts 4.x
//import echarts from 'echarts'
Vue.prototype.$echarts = echarts

import App from './App'

Vue.use(ElementUI)  // 注册使用Element
Vue.use(api)  // 注册使用API模块

Vue.prototype.global = global // 挂载全局配置模块

new Vue({
  el: '#app', i18n, router, store, render: h => h(App)
})
