import axios from 'axios';
import config from './config';
import Cookies from "js-cookie";
import router from '@/router'
import {MessageBox} from 'element-ui'

export default function $axios(options) {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: config.baseUrl,
      headers: config.headers,
      timeout: config.timeout,
      withCredentials: config.withCredentials
    })
    // request 请求拦截器
    instance.interceptors.request.use(
      config => {
        let token = Cookies.get('token')
        // 发送请求时携带token
        if (token) {
          config.headers.token = token
        } else {
          // 重定向到登录页面
          router.push('/loginindex')
        }
        return config
      },
      error => {
        // 请求发生错误时
        console.log('request:', error)
        // 判断请求超时
        if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
          console.log('timeout请求超时')
        }
        // 需要重定向到错误页面
        const errorInfo = error.response
        console.log(errorInfo)
        if (errorInfo) {
          error = errorInfo.data  // 页面那边catch的时候就能拿到详细的错误信息,看最下边的Promise.reject
          const errorStatus = errorInfo.status; // 404 403 500 ...
          // TODO: 2022.12.07. 缺乏error页面. stone zhao
          router.push({
            path: `/error/${errorStatus}`
          })
        }
        return Promise.reject(error) // 在调用的那边可以拿到(catch)你想返回的错误信息
      }
    )

    // response 响应拦截器
    instance.interceptors.response.use(
      response => {
        if ((response.data) && (response.data.code)) {
          let reponseMsg = ''
          switch (response.data.code) {
            case 400:
              reponseMsg = '请求错误'
              break
            case 401:
              reponseMsg = '未授权，请登录'
              if (response.data.msg != null) {
                MessageBox.alert(response.data.msg, '请检查登录', {
                  confirmButtonText: '确定',
                  showClose:false,
                }).then(() => {
                  // 重定向到登录页面
                  Cookies.set('token', '') // 放置token到Cookie
                  sessionStorage.removeItem("user")
                  //this.$router.push("/")
                  self.location = '';
                }) ;
              }
              else {
                MessageBox.alert(reponseMsg, '请检查登录', {
                  confirmButtonText: '确定',
                  showClose:false,
                }).then(() => {
                  // 重定向到登录页面
                  Cookies.set('token', '') // 放置token到Cookie
                  sessionStorage.removeItem("user")
                  //this.$router.push("/")
                  self.location = '';
                }) ;
                Cookies.set('token', '') // 放置token到Cookie
                sessionStorage.removeItem("user")
                //this.$router.push("/")
                self.location = '';
              }
              break
            case 403:
              reponseMsg = '拒绝访问'
              MessageBox.alert(reponseMsg, '请检查权限', {
                confirmButtonText: '确定',
                showClose: false,
              });
              break
            case 404:
              reponseMsg = `请求地址出错`
              break
            case 408:
              reponseMsg = '请求超时'
              break
            case 500:
              reponseMsg = '服务器内部错误'
              break
            case 501:
              reponseMsg = '服务未实现'
              break
            case 502:
              reponseMsg = '网关错误'
              break
            case 503:
              reponseMsg = '服务不可用'
              break
            case 504:
              reponseMsg = '网关超时'
              break
            case 505:
              reponseMsg = 'HTTP版本不受支持'
              break
            default:
          }
          if (reponseMsg !== '')
          {
            console.error(reponseMsg)
          }
        }
        return response.data
      },
      err => {
        if (err && err.response) {
          switch (err.response.status) {
            case 400:
              err.message = '请求错误'
              break
            case 401:
              err.message = '未授权，请登录'
              MessageBox.alert('登录失效', '请检查登录', {
                confirmButtonText: '确定',
                showClose:false,
              }).then(() => {
                // 重定向到登录页面
                //清除历史数据
                Cookies.set('token', '') // 放置token到Cookie
                sessionStorage.removeItem("user")
                //this.$router.push("/")
                self.location = '';
              }) ;
              break
            case 403:
              err.message = '拒绝访问'
              MessageBox.alert(err.message, '请检查权限', {
                confirmButtonText: '确定',
                showClose: false,
              });
              break
            case 404:
              err.message = `请求地址出错: ${err.response.config.url}`
              break
            case 408:
              err.message = '请求超时'
              break
            case 500:
              err.message = '服务器内部错误'
              break
            case 501:
              err.message = '服务未实现'
              break
            case 502:
              err.message = '网关错误'
              break
            case 503:
              err.message = '服务不可用'
              break
            case 504:
              err.message = '网关超时'
              break
            case 505:
              err.message = 'HTTP版本不受支持'
              break
            default:
          }
        }
        console.error(err)
        return Promise.reject(err) // 返回接口返回的错误信息
      }
    )
    // 请求处理
    instance(options).then(res => {
      resolve(res)
      return false
    }).catch(error => {
      reject(error)
    })
  })
}
