/**
 * 全局常量、方法封装模块
 * 通过原型挂载到Vue属性
 * 通过 this.Global 调用
 * 2022.06.28. stone
 */

/*本地环境
*/

/*

// 后台管理系统服务器地址, 此处通过nginx代理,只暴露出一个端口.
export const baseUrl = 'http://localhost:8080/backend'
export const baseLoginUrl = 'http://localhost:8089'
// 系统数据备份还原服务器地址，暂时不用.
export const backupBaseUrl = 'http://localhost:8082'
// websocket 暂时不用
export const HTTP_PATH = 'ws://localhost';

// 以下是生成登录二维码的配置
//  id
export const wxwechatLoginId = 'wx_qrcode'
//  appid,corpid
export const wxwechatLoginAppId = 'ww850b6c26096ac50e'
//  agentid,应用id
export const wxwechatLoginAgentId = '1000024'
// 微信登录临时地址
export const wxwechatLoginRedirectUrl = 'http://www.gemdalehealth.com/backend/wxwechatlogin'
//  state,自定义的状态.
export const wxwechatLoginState = 'wwLogin2023'
//  css链接,css链接地址.
export const wxwechatLoginCssHref = 'https://www.gemdalehealth.com/wxwechatcss/wxwechat.css'
//  lang,语言配置
export const wxwechatLoginLang = 'zh'
// 登录二维码配置完成.
//本地环境结束
 */

/*helmet in healthlong 环境.
 */
/*
// 后台管理系统服务器地址, 此处通过nginx代理,只暴露出一个端口.
export const baseUrl = 'http://dw.healthlong.com:9980/backend'
// export const baseLoginUrl = 'http://localhost:8089'
export const baseLoginUrl = 'http://dw.healthlong.com:9980'
// 系统数据备份还原服务器地址,暂时不用.
export const backupBaseUrl = 'http://localhost:8082'
// websocket 暂时不用
export const HTTP_PATH = 'ws://localhost';

// 以下是生成登录二维码的配置
//  id
export const wxwechatLoginId = 'wx_qrcode'
//  appid,corpid
export const wxwechatLoginAppId = 'ww850b6c26096ac50e'
//  agentid,应用id
export const wxwechatLoginAgentId = '1000024'
// 微信登录临时地址
export const wxwechatLoginRedirectUrl = 'http://dw.healthlong.com:9980/backend/wxwechatlogin'
//  state,自定义的状态.
export const wxwechatLoginState = 'wwLogin2023'
//  css链接,css链接地址.
export const wxwechatLoginCssHref = 'https://www.gemdalehealth.com/wxwechatcss/wxwechat.css'
//  lang,语言配置
export const wxwechatLoginLang = 'zh'
// 登录二维码配置完成.

*/

/*helmet in healthlong 环境结束.
 */

/*helmet环境.
 */
///*
// 后台管理系统服务器地址, 此处通过nginx代理,只暴露出一个端口.
export const baseUrl = 'https://www.highblue.top/backend'
// export const baseLoginUrl = 'http://localhost:8089'
export const baseLoginUrl = 'https://www.highblue.top'
// 系统数据备份还原服务器地址,暂时不用.
export const backupBaseUrl = 'http://localhost:8082'
// websocket 暂时不用
export const HTTP_PATH = 'ws://localhost';

// 以下是生成登录二维码的配置
//  id
export const wxwechatLoginId = 'wx_qrcode'
//  appid,corpid
export const wxwechatLoginAppId = 'ww850b6c26096ac50e'
//  agentid,应用id
export const wxwechatLoginAgentId = '1000024'
// 微信登录临时地址
export const wxwechatLoginRedirectUrl = 'https://www.highblue.top/backend/wxwechatlogin'
//export const wxwechatLoginRedirectUrl = 'http://www.highblue.top/backend/wxwechatlogin'
//  state,自定义的状态.
export const wxwechatLoginState = 'wwLogin2023'
//  css链接,css链接地址.
export const wxwechatLoginCssHref = 'https://www.gemdalehealth.com/wxwechatcss/wxwechat.css'
//  lang,语言配置
export const wxwechatLoginLang = 'zh'
// 登录二维码配置完成.

// */

/*helmet环境结束.
 */

/*gemdalehealth环境.
 */


/*
=======
//测试环境配置
// 后台管理系统服务器地址, 此处通过nginx代理,只暴露出一个端口.
export const baseUrl = 'https://www.gemdalehealth.cn/backend'
//export const baseLoginUrl = 'http://localhost:8089'
export const baseLoginUrl = 'https://www.gemdalehealth.cn/'
// 系统数据备份还原服务器地址,暂时不用.
export const backupBaseUrl = 'http://localhost:8082'
// websocket 暂时不用
export const HTTP_PATH = 'ws://localhost';

// 以下是生成登录二维码的配置
//  id
export const wxwechatLoginId = 'wx_qrcode'
//  appid,corpid
export const wxwechatLoginAppId = 'ww850b6c26096ac50e'
//  agentid,应用id
export const wxwechatLoginAgentId = '1000024'
export const wxwechatLoginRedirectUrl = 'https://www.gemdalehealth.cn/backend/wxwechatlogin'
//export const wxwechatLoginRedirectUrl = 'http://www.gemdalehealth.com/backend/wxwechatlogin'
//  state,自定义的状态.
export const wxwechatLoginState = 'wwLogin2023'
//  css链接,css链接地址.
export const wxwechatLoginCssHref = 'https://www.gemdalehealth.cn/wxwechatcss/wxwechat.css'
//  lang,语言配置
export const wxwechatLoginLang = 'zh'
// 登录二维码配置完成.


/*
// 生产环境配置
// 后台管理系统服务器地址, 此处通过nginx代理,只暴露出一个端口.
export const baseUrl = 'https://www.gemdalehealth.com/backend'
//export const baseLoginUrl = 'http://localhost:8089'
export const baseLoginUrl = 'https://www.gemdalehealth.com/'
// 系统数据备份还原服务器地址,暂时不用.
export const backupBaseUrl = 'http://localhost:8082'
// websocket 暂时不用
export const HTTP_PATH = 'ws://localhost';

// 以下是生成登录二维码的配置
//  id
export const wxwechatLoginId = 'wx_qrcode'
//  appid,corpid
export const wxwechatLoginAppId = 'ww850b6c26096ac50e'
//  agentid,应用id
export const wxwechatLoginAgentId = '1000024'
export const wxwechatLoginRedirectUrl = 'https://www.gemdalehealth.com/backend/wxwechatlogin'
//export const wxwechatLoginRedirectUrl = 'http://www.gemdalehealth.com/backend/wxwechatlogin'
//  state,自定义的状态.
export const wxwechatLoginState = 'wwLogin2023'
//  css链接,css链接地址.
export const wxwechatLoginCssHref = 'https://www.gemdalehealth.com/wxwechatcss/wxwechat.css'
//  lang,语言配置
export const wxwechatLoginLang = 'zh'
// 登录二维码配置完成.

*/

/*gemdalehealth环境结束.
 */

export default {
  baseUrl,
  baseLoginUrl,
  backupBaseUrl,
  wxwechatLoginId,
  wxwechatLoginAppId,
  wxwechatLoginAgentId,
  wxwechatLoginRedirectUrl,
  wxwechatLoginState,
  wxwechatLoginCssHref,
  wxwechatLoginLang,
  mqttWebsocketClient: {},
  setMqttWebsocketClient: function (newMqttWebsocketClient) {
      this.mqttWebsocketClient = newMqttWebsocketClient
    },
}

// mqtt server 的地址
//export const mqttServerHost = "101.34.118.240";
//in server:
export const mqttServerHost = "www.highblue.top";
// mqtt server 的端口
export const mqttServerPort = "9001";
// mqtt server 的访问路径
export const mqttServerEndPoint = "/mqtt";
// mqtt server 的主题
export const mqttServerTopic = "mtopic";
// mqtt server 是否使用TLS
export const mqttServerUseTLS = false;
// mqtt server 用户名
export const mqttServerUserName = "stone";
// mqtt server 密码
export const mqttServerPassword = "highblue";
// mqtt server 是否 cleansession
export const mqttServerCleanSession = true;

// mqtt client sub topic
export const mqttClientSubTopic = "mtopic";
// mqtt client sub qos
export const  mqttClientSubQos = 1;

// mqtt client pub topic
export const mqttClientPubTopic = "mtopic";
// mqtt client pub qos
export const  mqttClientPubQos = 1;
// mqtt client pub payload
export const  mqttClientPubPayload = '{ "msg": "Hello, I am browser." }';

