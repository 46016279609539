/*
 * 接口统一集成模块
 */
//org microservice.
//登录
import * as login from './modules/login'
//用户
import * as user from './modules/user'
//岗位
import * as post from './modules/post'
//机构
import * as org from './modules/org'
//部门
import * as dept from './modules/dept'
//地址区域
import * as area from './modules/area'
//角色
import * as role from './modules/role'
//菜单
import * as menu from './modules/menu'
//字典
import * as dict from './modules/dict'
//配置
import * as config from './modules/config'
//日志
import * as log from './modules/log'
//登陆日志
import * as loginlog from './modules/loginlog'
//消息管理
import * as messageinfo from './modules/messageinfo'
//消息用户管理
import * as messageinfouser from './modules/messageinfouser'

//device microservice.
//设备
import * as device from './modules/device'
import * as devicemessage from './modules/devicemessage'

//crm microservice.
//客户
import * as customer from './modules/customer'
import * as customerhealthinfo from './modules/crmhealthinfo'
import * as customercontactinfo from './modules/crmcontactinfo'
import * as customerleaveinfo from './modules/crmleaveinfo'
import * as customerfinancetype from './modules/crmfinancetype'

// 客户合同
import * as customercontract from './modules/crmcontract'


//标签
import * as tag from './modules/tagApi'

// finance microservice.
import * as financebillitemconfig from './modules/financebillitemconfig'
import * as financecustomeraccount from './modules/financecustomeraccount'
import * as financebillfee from './modules/financebillfee'
import * as financebillbase from './modules/financebillbase'
import * as financebillext from './modules/financebillext'
import * as financebillpay from './modules/financebillpay'


//space microservice.
//房态
import * as space from './modules/space'
import * as equipment from './modules/equipment'

//care microservice.
//日历
import * as calendarbase from './modules/calendarbase'
import * as calendarext from './modules/calendarext'

//服务内容
import * as servicecontentinfo from './modules/servicecontentinfo'

//套餐服务内容
import * as combinationservicecontentinfo from './modules/combinationservicecontentinfo'

//套餐服务等级
import * as combinationcarelevel from './modules/combinationcarelevel'

//照护管理
import * as customerservicecontentbase from './modules/customerservicecontentbase'
import * as customerservicecontentext from './modules/customerservicecontentext'
import * as customerservicecontentextplan from './modules/customerservicecontentextplan'
import * as carearea from './modules/carearea'
import * as careschedual from './modules/careschedual'
import * as careareaschedual from './modules/careareaschedual'
import * as careshiftcombine from './modules/careshiftcombine'
import * as careshiftbase from './modules/careshiftbase'
import * as careshiftext from './modules/careshiftext'
import * as attendancebase from './modules/attendancebase'
import * as attendancedetail from './modules/attendancedetail'

// 药单管理
import * as medicinemedicalorderbase from './modules/medicalmedicalorderbase'
import * as medicinemedicalorderext from './modules/medicalmedicalorderext'
import * as medicinewarehouse from './modules/medicalmedicinewarehouse'

//工单微服务
import * as workorderbase from './modules/workorderbase'
import * as accident from './modules/accident'
import * as urgent from './modules/urgent'

//评估模块
import * as assessment from './modules/assessment'

// 默认全部导出
export default {
    login,
    user,
    org,
    post,
    dept,
    area,
    role,
    menu,
    dict,
    config,
    log,
    loginlog,
    messageinfo,
    messageinfouser,
    space,
    equipment,
    calendarbase,
    calendarext,
    attendancebase,
    attendancedetail,

    medicinemedicalorderbase,
    medicinemedicalorderext,
    medicinewarehouse,

    servicecontentinfo,
    combinationcarelevel,
    combinationservicecontentinfo,
    customerservicecontentbase,
    customerservicecontentext,
    customerservicecontentextplan,
    careshiftcombine,
    careshiftbase,
    careshiftext,
    workorderbase,
    accident,
    urgent,
    carearea,
    careschedual,
    careareaschedual,
    customer,
    customerhealthinfo,
    customercontactinfo,
    customerleaveinfo,
    customerfinancetype,
    customercontract,

    financebillfee,
    financecustomeraccount,
    financebillbase,
    financebillext,
    financebillpay,
    financebillitemconfig,

    tag,
    device,
    messageLog: devicemessage,

    assessment,
}
