import axios from '../axios'

/*
 * 医护管理之医嘱单基础模块
 */

// 保存
export const save = (data) => {
    return axios({
        url: '/medicinemedicalorderbase/save',
        method: 'post',
        data
    })
}
// 删除
export const batchDelete = (data) => {
    return axios({
        url: '/medicinemedicalorderbase/delete',
        method: 'post',
        data
    })
}

// 分页查询
export const findPage = (data) => {
  return axios({
    url: '/medicinemedicalorderbase/findPage',
    method: 'post',
    data
  })
}
