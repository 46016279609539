import axios from '../axios'

/*
 * 照护区域模块
 */

// 保存
export const save = (data) => {
    return axios({
        url: '/carearea/save',
        method: 'post',
        data
    })
}

// 删除
export const batchDelete = (data) => {
    return axios({
        url: '/carearea/delete',
        method: 'post',
        data
    })
}

// 分页查询
export const findPage = (data) => {
  return axios({
    url: '/carearea/findPage',
    method: 'post',
    data
  })
}

// 获取所有的照护分区
export const findAllCareAreas = () => {
  return axios({
    url: '/carearea/findAllCareAreas',
    method: 'get'
  })
}

// 获取当前用户的照护分区
export const findUserCareAreas = () => {
  return axios({
    url: '/carearea/findUserCareAreas',
    method: 'get'
  })
}

// 根据照护分区编号，获取所有的用户，包括组长、医护士和照护士.
export const findUsersByCareAreaId = (params) => {
  return axios({
    url: '/carearea/findUsersByCareAreaId',
    method: 'get',
    params
  })
}

// 根据照护计划编号，获取所有的用户，包括组长、医护士和照护士.
export const findUsersByCustomerServiceBaseId = (params) => {
  return axios({
    url: '/carearea/findUsersByCustomerServiceBaseId',
    method: 'get',
    params
  })
}

